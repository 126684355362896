export enum ProfilesActionsTypes {
    ALL_PROFILES_IDS = "ALL_PROFILES_IDS",
    GET_PROFILES = "GET_PROFILES",
    GET_PROFILES_SUCCESS = "GET_PROFILES_SUCCESS",
    GET_PROFILES_FAILURE = "GET_PROFILES_FAILURE"
};

export interface IInitialState {
    allProfilesIds: number[],
    profiles: any[],
    loading: boolean,
    error: null | string,
};

interface IAllProfilesIds {
    type: ProfilesActionsTypes.ALL_PROFILES_IDS,
    payload: number[]
};

interface IGetProfiles {
    type: ProfilesActionsTypes.GET_PROFILES,
};

interface IGetProfilesSuccess {
    type: ProfilesActionsTypes.GET_PROFILES_SUCCESS,
    payload: any[]
};

interface IGetProfilesFailure {
    type: ProfilesActionsTypes.GET_PROFILES_FAILURE,
    payload: string
};

export type ProfilesActions =
    IAllProfilesIds |
    IGetProfiles |
    IGetProfilesSuccess |
    IGetProfilesFailure;
