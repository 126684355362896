import React, { useState } from "react";

const SlidingMenuContext = React.createContext();

export const SlidingMenuProvider = ({ children }) => {
    const [isShowSlidingMenu, setIsShowSlidingMenu] = useState(false);

    return (
        <SlidingMenuContext.Provider value={{ isShowSlidingMenu, setIsShowSlidingMenu }}>
            {children}
        </SlidingMenuContext.Provider>
    );
};

export default SlidingMenuContext;
