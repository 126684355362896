import { PeriodActions, PeriodActionsTypes, IInitialState } from "../../types/period";

const initialState: IInitialState = {
    period: "Today",
};

export const periodReducer = (state = initialState, action: PeriodActions): IInitialState => {
    switch (action.type) {
        case PeriodActionsTypes.PERIOD:
            return {
                ...state,
                period: action.payload
            }
        default:
            return state
    };
};
