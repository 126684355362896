import { FC } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { NavLink, useLocation } from "react-router-dom";

const Navigation: FC = () => {
    const location = useLocation();
    const permissions = useTypedSelector(state => state.auth.permissionsByFeatures);

    return (
        <div className="controls-stats-nav">
            {permissions && permissions.statistics ?
                <NavLink
                    to="/cabinet/new_statistics/"
                    className={ `${!location.pathname.includes("detailed") && !location.pathname.includes("data-export") ? "controls-stats-nav-item-active" : "controls-stats-nav-item"}` }
                    >
                        Основная статистика
                </NavLink> : null
            }
            {permissions && permissions.detailed_stats ?
                <NavLink
                    to="/cabinet/new_statistics/detailed"
                    className={ `${location.pathname.includes("detailed") ? "controls-stats-nav-item-active" : "controls-stats-nav-item"}` }
                >
                    Подробная статистика
                </NavLink> : null
            }
            {permissions && permissions.s3_integration ?
                <NavLink
                    to="/cabinet/new_statistics/data-export"
                    className={ `${location.pathname.includes("data-export") ? "controls-stats-nav-item-active" : "controls-stats-nav-item"}` }
                >
                    Экспорт данных
                </NavLink> : null
            }
        </div>
    );
};

export default Navigation;
