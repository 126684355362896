import React, { FC } from "react";
import './FetchError.scss';

interface ErrorProps {
    text: string|null;
    isButtonDisabled?: boolean;
    isButton?: boolean;
    isIcon?: boolean;
    align?: string
    clickHeandler: React.MouseEventHandler<HTMLButtonElement>;
};

const FetchError: FC<ErrorProps> = ({ text, isButton, isIcon, isButtonDisabled, align, clickHeandler }) => {
    return (
        <div className="fetch-error" style={{ justifyContent: align }}>
            {isButton &&
                <button
                    type="button"
                    className="fetch-error-button"
                    disabled={isButtonDisabled}
                    onClick={clickHeandler}
                />
            }
            {isIcon &&
                <div className="fetch-error-img"></div>
            }
            <p className="fetch-error-text">{text}</p>
        </div>
    );
};

export default FetchError;
