import { FC } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { checkCount } from '../../stats/statsCount';
import HighlightsInfo from '../../stats/highlights/HighlightsInfo';
import '../../stats/highlights/Highlights.scss';
import '../PdfReport.scss';

const PageOne: FC = () => {
    const highlightsDataResult = useTypedSelector(state => state.statsHighlights.dataResult);
    const loading = useTypedSelector(state => state.statsHighlights.loading);
    const error = useTypedSelector(state => state.statsHighlights.error);

    const statsCount = (count: number, title: string, type: string) => {
        return (
            <div className={`stats-highlights-report-data-${type}-block`}>
                <span>{title}</span>
                <p>{checkCount(count)}</p>
            </div>
        )
    };

    return (
        <>
            <div className="stats-highlights-report">
                <h2 className="stats-highlights-report-title">Основные показатели</h2>

                <div className="stats-highlights-report-data">
                    <div className="stats-highlights-report-data-all">
                        {error || highlightsDataResult.requests === 0 ?
                            <div>{statsCount(0, 'Все запросы', 'all')}</div> :
                            <div>{statsCount(loading ? 0 : highlightsDataResult.requests, 'Все запросы', 'all')}</div>
                        }
                    </div>

                    <div className="stats-highlights-report-data-filtered">
                        {error || highlightsDataResult.requests === 0 ?
                            <div>{statsCount(loading ? 0 : 0, 'Разрешенные запросы', 'filtered')}</div> :
                            <div>{statsCount(loading ? 0 : highlightsDataResult.requests - highlightsDataResult.blocks, 'Разрешенные запросы', 'filtered')}</div>
                        }
                    </div>

                    <div className="stats-highlights-report-data-blocked">
                        {error || highlightsDataResult.requests === 0 ?
                            <div>{statsCount(loading ? 0 : 0, 'Заблокированные запросы', 'blocked')}</div> :
                            <div>{statsCount(loading ? 0 : highlightsDataResult.blocks - highlightsDataResult.dangerous_blocks, 'Заблокированные запросы', 'blocked')}</div>
                        }
                    </div>

                    <div className="stats-highlights-report-data-threats">
                        {error || highlightsDataResult.requests === 0 ?
                            <div>{statsCount(loading ? 0 : 0, 'Предотвращенные угрозы', 'threats')}</div> :
                            <div>{statsCount(loading ? 0 : highlightsDataResult.dangerous_blocks, 'Предотвращенные угрозы', 'threats')}</div>
                        }
                    </div>
                </div>

                <p className="stats-highlights-report-info-title">Общие сведения о потоке ваших запросов и общее количество конкретных типов запросов.</p>

                <HighlightsInfo />
            </div>
        </>

    )
};

export default PageOne;
