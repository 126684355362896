export enum StatsCategoriesActionsTypes {
    GET_STATS_CATEGORIES = "GET_STATS_CATEGORIES",
    GET_STATS_CATEGORIES_SUCCESS = "GET_STATS_CATEGORIES_SUCCESS",
    GET_STATS_CATEGORIES_FAILURE = "GET_STATS_CATEGORIES_FAILURE",
    STATS_CATEGORIES_REQUEST = "STATS_CATEGORIES_REQUEST",
    STATS_CATEGORIES_DATA = "STATS_CATEGORIES_DATA",
    STATS_CATEGORIES_DATA_RESULT = "STATS_CATEGORIES_DATA_RESULT",
    STATS_CATEGORIES_LAST_MONTH_DATA_RESULT = "STATS_CATEGORIES_LAST_MONTH_DATA_RESULT"
};

export interface ICategotiesItemStats {
    cat: {
        id: string|number,
        is_dangerous: boolean,
        title: string
    },
    blocks: number,
    requests: number,
};

export interface IInitialState {
    statsCategories: string,
    statsCategoriesData: ICategotiesItemStats[],
    loading: boolean,
    error: null | string,
    request: any,
    dataResult: any,
    lastMontnDataResult: any
};

interface IGetStatsCategories {
    type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES,
};

interface IGetStatsCategoriesSuccess {
    type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_SUCCESS,
    payload: string
};

interface IGetStatsCategoriesFailure {
    type: StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_FAILURE,
    payload: string
};

interface IStatsCategoriesRequest {
    type: StatsCategoriesActionsTypes.STATS_CATEGORIES_REQUEST,
    payload: ICategotiesItemStats[]
};

interface IStatsCategoriesData {
    type: StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA,
    payload: ICategotiesItemStats[]
};

interface IStatsCategoriesDataResult {
    type: StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA_RESULT,
    payload: any
};

interface IStatsCategoriesLastMonthDataResult {
    type: StatsCategoriesActionsTypes.STATS_CATEGORIES_LAST_MONTH_DATA_RESULT,
    payload: any
};

export type StatsCategoriesActions =
    IGetStatsCategories |
    IGetStatsCategoriesSuccess |
    IGetStatsCategoriesFailure |
    IStatsCategoriesRequest |
    IStatsCategoriesData |
    IStatsCategoriesDataResult |
    IStatsCategoriesLastMonthDataResult;
