import { FC } from "react";
import { NavLink } from "react-router-dom";
import "./Button.scss";

interface ButtonLinkProps {
    name?: string;
    link: string;
    modifier: string;
    width?: string;
    state?: any
};

const ButtonLink: FC<ButtonLinkProps> = ({ name, link, modifier, width, state }) => {
    return (
        <NavLink
            to={link}
            className={`button-${modifier}`}
            style={{width: width}}
            state={state}
        >{name}
        </NavLink>
    );
};

export default ButtonLink;
