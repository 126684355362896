import React, { FC, useState } from "react";
import { useActions } from "../../hooks/useActions";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IReportRequestData } from "../../types/statsMonthlyReport";
import { IAdminItem } from "../../types/admins";
import { updateRecipientsList } from "../../api";
import Modal from "../UI/modal/Modal";
import Toggler from "../UI/toggler/Toggler";

const RecipientsModal: FC = () => {
    const {
        fetchRecipientsList,
        notification,
    } = useActions();

    const isAuthData = useTypedSelector(state => state.auth.authData);
    const admins = useTypedSelector(state => state.admins.adminsList);
    const checkReportData = useTypedSelector(state => state.statsMonthlyReport.checkRecipientsList)[0];
    const loading = useTypedSelector(state => state.statsMonthlyReport.loading);

    const [isAssignRecipient, setIsAssignRecipient] = useState(false);
    const [updateReportRequestData, setUpdateRportRequestData] = useState<IReportRequestData>(checkReportData && {
        recipients: checkReportData && checkReportData.recipients,
        file_types: checkReportData && checkReportData.file_types,
        period: checkReportData && checkReportData.period,
        is_active: checkReportData && checkReportData.is_active
    });

    let getRecipientsIds = localStorage.getItem("ids") ? Object.assign([], JSON.parse(localStorage.getItem("ids") || "")) : [];

    const onAssignRecipient = (data: any) => {
        setIsAssignRecipient(!isAssignRecipient);

        if (data.target.checked) {
            getRecipientsIds.push(+data.target.id);

            fetchRecipientsList(isAuthData.user_id, updateRecipientsList, { ...updateReportRequestData, recipients: getRecipientsIds});

            notification({
                type: 'success',
                message: `${data.target.name} успешно добавлен в список автоматической отправки ежемесячных отчетов.`,
                isNotification: true
            })
        } else {
            let index = getRecipientsIds.indexOf(+data.target.id);

            if (index !== -1) {
                localStorage.setItem("ids", JSON.stringify(getRecipientsIds.splice(index, 1)));
            }

            fetchRecipientsList(isAuthData.user_id, updateRecipientsList, { ...updateReportRequestData, recipients: getRecipientsIds});

            notification({
                type: 'success',
                message: `${data.target.name} успешно удален из списка автоматической отправки ежемесячных отчетов.`,
                isNotification: true
            })
        }
    }

    return (
        <Modal
            title="Назначить получателей"
            width="608"
            content={
                <div>
                    <p>Вы можете добавлять только получателей из вашего списка администраторов или аудиторов</p>
                    <ul className="controls-report-admins-list-head">
                        <li>Получатели</li>
                        <li>Роли</li>
                        <li></li>
                    </ul>
                    <ul className="controls-report-admins-list">
                        {admins ?
                            admins.map((admin: IAdminItem, index: number) => (
                                <li key={index} className="controls-report-admins-list-item">
                                    <div>{admin.email}</div>
                                    <div>{admin.role.toLocaleUpperCase()}</div>

                                    {checkReportData &&
                                        <Toggler
                                            id={admin.id.toString()}
                                            name={admin.username}
                                            changeHeandler={onAssignRecipient}
                                            checked={checkReportData.recipients.find(item => admin.id == item) ? true : false}
                                            isDisabled={loading ? true : false}
                                        />
                                    }
                                </li>
                            )) :
                            <p>У вас нет администраторов или аудиторов, <a href="#">Добавить</a></p>
                        }
                    </ul>
                </div>
            }
        />
    );
};

export default RecipientsModal;
