import { StatsDetailedActions, StatsDetailedActionsTypes, IInitialState } from "../../types/statsDetailed";

const initialState: IInitialState = {
    statsDetailed: "",
    statsDetailedData: [],
    loading: false,
    error: null,
    getDetailedInfo: {
        domain: "",
        status: ""
    },
    getInfoLoading: false,
    getInfoError: null,
    request: null
};

export const statsDetailedReducer = (state = initialState, action: StatsDetailedActions): IInitialState => {
    switch (action.type) {
        case StatsDetailedActionsTypes.GET_STATS_DETAILED:
            return {
                ...state,
                loading: true,
                error: null,
                statsDetailed: ""
            }
        case StatsDetailedActionsTypes.GET_STATS_DETAILED_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsDetailed: action.payload
            }
        case StatsDetailedActionsTypes.GET_STATS_DETAILED_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsDetailed: ""
            }
        case StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO:
            return {
                ...state,
                getInfoLoading: true,
                getInfoError: null,
                getDetailedInfo: {
                    domain: "",
                    status: ""
                }
            }
        case StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO_SUCCESS:
            return {
                ...state,
                getInfoLoading: false,
                getInfoError: null,
                getDetailedInfo: action.payload
            }
        case StatsDetailedActionsTypes.GET_STATS_DETAILED_INFO_FAILURE:
            return {
                ...state,
                getInfoLoading: false,
                getInfoError: action.payload,
                getDetailedInfo: {
                    domain: "",
                    status: ""
                }
            }
        case StatsDetailedActionsTypes.STATS_DETAILED_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsDetailedActionsTypes.STATS_DETAILED_DATA:
            return {
                ...state,
                statsDetailedData: action.payload
            }
        default:
            return state
    };
};
