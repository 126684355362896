import { AdminActions, AdminActionsTypes, IInitialState } from "../../types/admins";

const initialState: IInitialState = {
    adminId: '',
    admin: [],
    adminsList: [],
    update: [],
    loading: false,
    error: null,
};

export const adminsReducer = (state = initialState, action: AdminActions): IInitialState =>  {
    switch (action.type) {
        case AdminActionsTypes.ADMIN_ID:
            return {
                ...state,
                adminId: action.payload
            }
        case AdminActionsTypes.GET_ADMINS_LIST:
            return {
                ...state,
                loading: true,
                error: null,
                adminsList: []
            }
        case AdminActionsTypes.GET_ADMINS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                adminsList: action.payload
            }
        case AdminActionsTypes.GET_ADMINS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                adminsList: []
            }
        default:
            return state
    };
};
