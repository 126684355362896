import * as AuthSession from "./auth_session";
import * as Auth from "./auth";
import * as Navbar from "./navbarItems";
import * as Notification from "./notification";
import * as AdminId from "./adminId"
import * as Admins from "./admins"
import * as Profiles from "./profiles";
import * as Period from "./period";
import * as SelectedProfile from "./allProfilesIds";
import * as AllProfilesIds from "./statsRequestData";
import * as StatsHighlightsApiRequest from "./statsApiRequests";
import * as StatsActivityApiRequest from "./statsApiRequests";
import * as StatsCategoriesApiRequest from "./statsApiRequests";
import * as StatsDomainsApiRequest from "./statsApiRequests";
import * as StatsDevicesApiRequest from "./statsApiRequests";
import * as statsDetailedRequest from "./statsApiRequests";
import * as StatsExport from "./statsExport";
import * as StatsMonthlyReport from "./statsMonthlyReport";
import * as Devices from "./devices";
import * as Catgories from "./categories";
import * as S3Integration from "./dataExport/s3Integration";

const actionCreators = {
    ...AuthSession,
    ...Auth,
    ...Navbar,
    ...Notification,
    ...AdminId,
    ...Admins,
    ...Profiles,
    ...Period,
    ...SelectedProfile,
    ...AllProfilesIds,
    ...StatsHighlightsApiRequest,
    ...StatsActivityApiRequest,
    ...StatsCategoriesApiRequest,
    ...StatsDomainsApiRequest,
    ...StatsDevicesApiRequest,
    ...statsDetailedRequest,
    ...StatsExport,
    ...StatsMonthlyReport,
    ...Devices,
    ...Catgories,
    ...S3Integration
};

export default actionCreators;
