export enum PeriodActionsTypes {
    PERIOD = "PERIOD",
};

export interface IInitialState {
    period: string
};

interface IPeriod {
    type: PeriodActionsTypes.PERIOD,
    payload: string
};

export type PeriodActions = IPeriod;
