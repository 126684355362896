export enum StatsDevicesActionsTypes {
    GET_STATS_DEVICES = "GET_STATS_DEVICES",
    GET_STATS_DEVICES_SUCCESS = "GET_STATS_DEVICES_SUCCESS",
    GET_STATS_DEVICES_FAILURE = "GET_STATS_DEVICES_FAILURE",
    GET_STATS_DEVICE_DOMAINS = "GET_STATS_DEVICE_DOMAINS",
    GET_STATS_DEVICE_DOMAINS_SUCCESS = "GET_STATS_DEVICE_DOMAINS_SUCCESS",
    GET_STATS_DEVICE_DOMAINS_FAILURE = "GET_STATS_DEVICE_DOMAINS_FAILURE",
    STATS_DEVICES_DATA = "STATS_DEVICES_DATA",
    STATS_DEVICES_REQUEST = "STATS_DEVICES_REQUEST",
    STATS_DEVICES_DATA_RESULT = "STATS_DEVICES_DATA_RESULT",
    SELECTED_DEVICE = "SELECTED_DEVICE",
    DEVICE_LIST = "DEVICE_LIST"
};

export interface ISelectedDevice {
    ident: string|number,
    data_type: string
};

export interface IDeviceItem {
    token: number,
    requests: number,
    blocks: number,
    ipv4: string[],
    ipv6: any[],
    secure: boolean
};

export interface IDeviceList {
    id: number|null,
    ident: any,
    name: string,
    device_type: string,
    field_type: string,
    comment: string
};

export interface IInitialState {
    statsDevices: string,
    statsDevicesData: IDeviceItem[],
    selectedDevice: ISelectedDevice,
    deviceList: IDeviceList[],
    loading: boolean,
    error: null | string,
    getDeviceDomains: any[],
    getDevicesLoading: boolean,
    getDevicesError: null | string,
    request: any,
    dataResult: any
};

interface IGetStatsDevices {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICES,
};

interface IGetStatsDevicesSuccess {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICES_SUCCESS,
    payload: string
};

interface IGetStatsDevicesFailure {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICES_FAILURE,
    payload: string
};

interface IGetStatsDeviceDomains {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS,
};

interface IGetStatsDeviceDomainsSuccess {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_SUCCESS,
    payload: any[]
};

interface IGetStatsDeviceDomainsFailure {
    type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_FAILURE,
    payload: string
};

interface IStatsDevicesData {
    type: StatsDevicesActionsTypes.STATS_DEVICES_DATA,
    payload: IDeviceItem[]
};

interface IGetDeviceList {
    type: StatsDevicesActionsTypes.DEVICE_LIST,
    payload: IDeviceList[]
};

interface IStatsDevicesRequest {
    type: StatsDevicesActionsTypes.STATS_DEVICES_REQUEST,
    payload: any
};

interface IStatsDevicesDataResult {
    type: StatsDevicesActionsTypes.STATS_DEVICES_DATA_RESULT,
    payload: any
};

export type StatsDevicesActions =
    IGetStatsDevices |
    IGetStatsDevicesSuccess |
    IGetStatsDevicesFailure |
    IGetStatsDeviceDomains |
    IGetStatsDeviceDomainsSuccess |
    IGetStatsDeviceDomainsFailure |
    IGetDeviceList |
    IStatsDevicesData |
    IStatsDevicesRequest |
    IStatsDevicesDataResult;
