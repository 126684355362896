import { FC, useState, useEffect } from "react";
import axios from "axios";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { StatsDevicesActionsTypes } from "../../../types/statsDevices";
import { IDomainItem } from "../../../types/statsDomains";
import { useDispatch } from "react-redux";
import { NavLink } from "react-router-dom";
import ProgressLoader from "../../UI/progress-loader/ProgressLoader";
import FetchError from "../../UI/fetch-error/FetchError";
import './Devices.scss';

const DeviceItem: FC<any> = ({
        device,
        deviceName,
        deviceType,
        requests,
        blocks,
        secure,
        sortChange,
        paginationChange
    }) => {
    const dispatch = useDispatch();
    const token = useTypedSelector(state => state.auth.token);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);
    const categories = useTypedSelector(state => state.statsCategories.statsCategoriesData);
    const error = useTypedSelector(state => state.statsDevices.getDevicesError);

    const [itemIsShow, setItemIsShow] = useState<boolean>(false);
    const [requestDeviceData, setRequestDeviceData] = useState(requestData);
    const [domainsDataJson, setDomainsDataJson] = useState<string>('');
    const [domainsDataResult, setDomainsDataResult] = useState<any[]>([]);
    const [loadingState, setLoadingState] = useState<boolean>(false)

    const formatter = new Intl.NumberFormat('en-US');

    const deviceItemHandler = (e: any) => {
        switch (deviceType) {
            case "token":
                setRequestDeviceData({ 
                    ...requestData, tokens: [e.currentTarget.title], 
                    limit: 5, 
                    secure: secure 
                });
                break;
            case "ipv4":
                setRequestDeviceData({ 
                    ...requestData, ipv4_addresses: [e.currentTarget.title], 
                    limit: 5, 
                    secure: secure 
                });
                break;
            case "ipv6":
                setRequestDeviceData({ 
                    ...requestData, ipv6_networks: [e.currentTarget.title], 
                    limit: 5, 
                    secure: secure 
                });
                break;
            default:
                break;
        }

        setItemIsShow(!itemIsShow);
    };

    //get domains

    let recursionCount = 0;

    const getDomains = () => {
        setLoadingState(true);
        dispatch({
            type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS
        });

        axios.post(`${process.env.REACT_APP_STATS_URL}/api/rest/v2/get_domains_activity/`, requestDeviceData, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then(response => {
            if (response.data.status.includes('complete')) {
                setLoadingState(false);
                setDomainsDataJson(response.data.message);

                return dispatch({
                    type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_SUCCESS,
                    payload: response.data.message
                });
            } else {
                return setTimeout(() => {
                    recursionCount++

                    //exit from recursion with a long query
                    if (recursionCount >= 20) {
                        return dispatch({
                            type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_FAILURE,
                            payload: 'Упс! Что-то пошло не так... Повторите попытку позже..'
                        });
                    } else {
                        return getDomains();
                    }
                }, 1000);
            }
        })
        .catch(() => {
            dispatch({
                type: StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_FAILURE,
                payload: 'Упс! Что-то пошло не так... Повторите попытку позже..'
            });
        });
    };

    // get domains to json
    useEffect(() => {
        axios.get(domainsDataJson, {
            headers: { Authorization: `Bearer ${token.access}` }
        })
        .then((response) => {
            setDomainsDataResult(Array.from(response.data));
        })
        .catch(() => {
            console.log('error');
        })
    }, [domainsDataJson]);

    useEffect(() => {
        if (itemIsShow) {
            getDomains();
        }
    }, [itemIsShow, token]);

    //map category ids
    const itemCategories = (data: any[]) => {
        let cats: any[] = [];

        data.map((cat_ids) => {
            return categories.filter((item) => {
                if (item.cat.id === cat_ids) {
                    return cats.push(item.cat.title)
                }
            })
        });

        return cats.join(', ');
    };

    //close devices detailed when sorted or pagination change
    useEffect(() => {
        setItemIsShow(false);

    }, [sortChange, paginationChange]);

    return (
        <div className="stats-devices-list">
            <ul
                className="stats-devices-list-item"
                title={device}
                onClick={(e) => deviceItemHandler(e)}
                style={{ backgroundColor: itemIsShow ? "rgba(28, 102, 246, 0.04)" : "" }}
            >
                <button
                    type="button"
                    value={device}
                    className={`stats-devices-list-item-${itemIsShow ? "open" : "close"}`}
                />
                <li className="stats-devices-list-item-domains">{deviceName}</li>
                <li>{secure ? "DoH/DoT" : "DNS"}</li>
                <li>{formatter.format(requests)}</li>
                <li>{formatter.format(blocks)}</li>
            </ul>

            {itemIsShow &&
                <div className="stats-devices-list-item-domains">
                    {loadingState &&
                       <ProgressLoader />
                    }
                    <div
                        className="stats-devices-list-item-domains-wrapper"
                        style={{
                            opacity: loadingState || error ? 0.2 : 1,
                            pointerEvents: loadingState || error ? 'none' : 'auto'

                        }}
                    >
                        <ul className="stats-devices-list-item-domains-header">
                            <li>Домены</li>
                            <li>Категории</li>
                            <li>Запросы</li>
                            <li>Статус</li>
                        </ul>

                        {domainsDataResult && domainsDataResult.map((item: IDomainItem, index: number) => (
                            item.cat_ids &&
                            <ul key={index} className="stats-devices-list-item-domains-item">
                                <li>{item.domain}</li>
                                <li>{itemCategories(item.cat_ids) ? itemCategories(item.cat_ids) : "Внутренняя категория"}</li>
                                <li>{item.requests}</li>
                                <li>
                                    <span className={`status-${item.requests === item.blocks ? 'blocked' : item.blocks === 0 ? 'allowed' : 'mixed'}`}>
                                        {item.requests === item.blocks ? 'Заблокированный' : item.blocks === 0 ? 'Разрешенный' : 'Смешанный'}
                                    </span>
                                </li>
                            </ul>
                        ))}

                        <NavLink to="/cabinet/new_statistics/detailed" className="stats-devices-list-item-domains-detailed">Подробная статистика</NavLink>
                    </div>

                    {error &&
                        <FetchError
                            text={error}
                            isButton={true}
                            isButtonDisabled={loadingState ? true : false}
                            isIcon={false}
                            clickHeandler={getDomains}
                        />
                    }
                </div>
            }
        </div>
    )
};

export default DeviceItem;
