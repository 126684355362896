import React, { useState, useEffect } from 'react';

export const useCheckBreakpoint = (breakpoint: number) => {
    const [isMobile, setIsMobile] = useState<boolean>(false);

    useEffect(() => {
        const handleResize = () => {
            window.outerWidth <= breakpoint ? setIsMobile(true) : setIsMobile(false);
        };
        handleResize();

        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return isMobile;
};
