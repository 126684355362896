export enum NetworksActionsTypes {
    GET_NETWORKS = "GET_NETWORKS",
    GET_NETWORKS_SUCCESS = "GET_NETWORKS_SUCCESS",
    GET_NETWORKS_FAILURE = "GET_NETWORKS_FAILURE",
};

export interface INetworksResponseItem {
    id: number|null,
    type: string,
    ipv4: string,
    ipv6: string,
    hostname: string,
    profile_id: number|null,
    comment: string
};

export interface INetworksResponse {
    next_page_link: number|null,
    previous_page_link: number|null,
    total_items: number|null,
    total_pages: number|null,
    items_per_page: number|null,
    page_number: number|null,
    results: INetworksResponseItem[]
};

export interface IInitialState {
    networks: INetworksResponse,
    loading: boolean,
    error: string|null,
};

interface IGetNetworks {
    type: NetworksActionsTypes.GET_NETWORKS,
};

interface IGetNetworksSuccess {
    type: NetworksActionsTypes.GET_NETWORKS_SUCCESS,
    payload: INetworksResponse
};

interface IGetNetworksFailure {
    type: NetworksActionsTypes.GET_NETWORKS_FAILURE,
    payload: string
};

export type NetworksActions =
    IGetNetworks |
    IGetNetworksSuccess |
    IGetNetworksFailure;
