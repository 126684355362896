export enum CategoriesActionsTypes {
    GET_CATEGORIES_LIST = "GET_CATEGORIES_LIST",
    GET_CATEGORIES_LIST_SUCCESS = "GET_CATEGORIES_LIST_SUCCESS",
    GET_CATEGORIES_LIST_FAILURE = "GET_CATEGORIES_LIST_FAILURE",
}

export interface ICategoriesItem {
    id: number|null,
    is_dangerous: boolean,
    title: string
};

export interface ICategoriesResponse {
    next_page_link: number|null,
    previous_page_link: number|null,
    total_items: number|null,
    total_pages: number|null,
    items_per_page: number|null,
    page_number: number|null,
    results: ICategoriesItem[]
};

export interface IInitialState {
    categories: ICategoriesResponse,
    loading: boolean,
    error: string|null,
};

interface IGetCategoriesList {
    type: CategoriesActionsTypes.GET_CATEGORIES_LIST,
};

interface IGetCategoriesListSuccess {
    type: CategoriesActionsTypes.GET_CATEGORIES_LIST_SUCCESS,
    payload: ICategoriesResponse
};

interface IGetCategoriesListFailure {
    type: CategoriesActionsTypes.GET_CATEGORIES_LIST_FAILURE,
    payload: string
};

export type CategoriesActions =
    IGetCategoriesList |
    IGetCategoriesListSuccess |
    IGetCategoriesListFailure;
