import { StatsDomainsActions, StatsDomainsActionsTypes, IInitialState } from "../../types/statsDomains";

const initialState: IInitialState = {
    statsDomains: "",
    statsDomainsData: [],
    loading: false,
    error: null,
    getDomainDevices: [],
    getDevicesLoading: false,
    getDevicesError: null,
    request: null,
    dataResult: null
};

export const statsDomainsReducer = (state = initialState, action: StatsDomainsActions): IInitialState => {
    switch (action.type) {
        case StatsDomainsActionsTypes.GET_STATS_DOMAINS:
            return {
                ...state,
                loading: true,
                error: null,
                statsDomains: ""
            }
        case StatsDomainsActionsTypes.GET_STATS_DOMAINS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsDomains: action.payload
            }
        case StatsDomainsActionsTypes.GET_STATS_DOMAINS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsDomains: ""
            }
        case StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES:
            return {
                ...state,
                getDevicesLoading: true,
                getDevicesError: null,
                getDomainDevices: []
            }
        case StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_SUCCESS:
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesError: null,
                getDomainDevices: action.payload
            }
        case StatsDomainsActionsTypes.GET_STATS_DOMAIN_DEVICES_FAILURE:
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesError: action.payload,
                getDomainDevices: []
            }
        case StatsDomainsActionsTypes.STATS_DOMAINS_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsDomainsActionsTypes.STATS_DOMAINS_DATA:
            return {
                ...state,
                statsDomainsData: action.payload
            }
        case StatsDomainsActionsTypes.STATS_DOMAINS_DATA_RESULT:
            return {
                ...state,
                dataResult: action.payload
            }
        default:
            return state
    };
};
