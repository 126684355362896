import { Dispatch } from "redux";
import { StatsMonthlyReportActions, StatsMonthlyReportActionsTypes, IReportRequestData } from "../../types/statsMonthlyReport";
import { getRecipientsList } from "../../api";

export const fetchReportData = (requestData: IReportRequestData) => (
    {
        type: StatsMonthlyReportActionsTypes.RECIPIENTS_REQUEST_DATA,
        payload: requestData
    }
);

export const checkRecipientsList = (user_id: number | string) =>
    async (dispatch: Dispatch<StatsMonthlyReportActions>): Promise<any> => {
        dispatch({
            type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST,
        });

        try {
            const response = await getRecipientsList(user_id);

            dispatch({
                type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_SUCCESS,
                payload: response.data
            });

            localStorage.setItem("ids", JSON.stringify(response.data.recipients))
        } catch (error: any) {
            dispatch({
                type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_FAILURE,
                payload: "error"
            });
        }
    }

export const fetchRecipientsList = (user_id: number | string, action: any, data: IReportRequestData) =>
    async (dispatch: Dispatch<StatsMonthlyReportActions>): Promise<any> => {
        // dispatch({
        //     type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST,
        // });

        try {
            const response = await action(user_id, data);

            dispatch({
                type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_SUCCESS,
                payload: response.data
            });
        } catch (error: any) {
            dispatch({
                type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_FAILURE,
                payload: "error"
            });
        }
    }
