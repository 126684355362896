import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { uid } from "../uid";

const AuthContext = React.createContext();

export const AuthProvider = ({ children }) => {
    const navbarItems = useSelector(state => state.navbar.navbarItems);
    const [navbar, setNavbar] = useState([]);

    let modifyUrlMenu = [];
    let modifyMenu = navbarItems;

    useEffect(() => {
        modifyMenu.map(item => {
            modifyUrlMenu.push({ ...item, url: item.url });
        });

        setNavbar(modifyUrlMenu);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navbarItems]);

    return (
        <AuthContext.Provider value={{ navbar }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthContext;
