import React, { useState } from "react";

const ModalContext = React.createContext();

export const ModalProvider = ({ children }) => {
    const [isShowRecipientsModal, setIsShowRecipientsModal] = useState(false);
    const [isShowS3IntegrationCompleteModal, setIsShowS3IntegrationCompleteModal] = useState(false);
    const [isShowS3IntegrationDeleteModal, setIsShowS3IntegrationDeleteModal] = useState(false);

    return (
        <ModalContext.Provider value={{
            isShowRecipientsModal,
            setIsShowRecipientsModal,
            isShowS3IntegrationCompleteModal,
            setIsShowS3IntegrationCompleteModal,
            isShowS3IntegrationDeleteModal,
            setIsShowS3IntegrationDeleteModal
        }}>
            {children}
        </ModalContext.Provider>
    );
};

export default ModalContext;
