export enum AgentInfoActionsTypes {
    GET_AGENT_INFO = "GET_AGENT_INFO",
    GET_AGENT_INFO_SUCCESS = "GET_AGENT_INFO_SUCCESS",
    GET_AGENT_INFO_FAILURE = "GET_AGENT_INFO_FAILURE",
};

export interface IAgentInfoResponseItem {
    id: number|null,
    uid: number|null,
    user: number|null,
    token: string,
    version: string,
    os_info: string,
    hostname: string,
    address: string,
    profile_id: number|null,
    agent_type: string,
    comment: string
};

export interface IAgentInfoResponse {
    next_page_link: number|null,
    previous_page_link: number|null,
    total_items: number|null,
    total_pages: number|null,
    items_per_page: number|null,
    page_number: number|null,
    results: IAgentInfoResponseItem[]
};

export interface IInitialState {
    agentInfo: IAgentInfoResponse,
    loading: boolean,
    error: string|null,
};

interface IGetAgentInfo {
    type: AgentInfoActionsTypes.GET_AGENT_INFO,
};

interface IGetAgentInfoSuccess {
    type: AgentInfoActionsTypes.GET_AGENT_INFO_SUCCESS,
    payload: IAgentInfoResponse
};

interface IGetAgentInfoFailure {
    type: AgentInfoActionsTypes.GET_AGENT_INFO_FAILURE,
    payload: string
};

export type AgentInfoActions =
    IGetAgentInfo |
    IGetAgentInfoSuccess |
    IGetAgentInfoFailure;
