import { CategoriesActions, CategoriesActionsTypes, IInitialState } from "../../types/categories";

const initialState: IInitialState = {
    categories: {
        next_page_link: null,
        previous_page_link: null,
        total_items: null,
        total_pages: null,
        items_per_page: null,
        page_number: null,
        results: [{
            id: null,
            is_dangerous: false,
            title: ""
        }]
    },
    loading: false,
    error: null,
};

export const categoriesReducer = (state = initialState, action: CategoriesActions): IInitialState =>  {
    switch (action.type) {
        case CategoriesActionsTypes.GET_CATEGORIES_LIST:
            return {
                ...state,
                loading: true,
                error: null,
                categories: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        is_dangerous: false,
                        title: ""
                    }]
                }
            }
        case CategoriesActionsTypes.GET_CATEGORIES_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                categories: action.payload
            }
        case CategoriesActionsTypes.GET_CATEGORIES_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                categories: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        is_dangerous: false,
                        title: ""
                    }]
                }
            }
        default:
            return state
    };
};
