import React, { FC } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import Controls from '../controls/Controls';
import Highlights from './highlights/Highlights';
import TopFacts from './top-facts/TopFacts';
import Activity from './activity/Activity';
import Categories from './categories/Categories';
import Domains from './domains/Domains';
import Devices from './devices/Devices';
import './Stats.scss';

const Stats: FC = () => {
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);

    return (
        <div className="stats">
            <Controls />
            <Highlights />
            <TopFacts />
            <Categories />
            <Activity />
            <Domains />
            {
                requestData.ipv4_addresses.length === 0 &&
                requestData.ipv6_networks.length === 0 &&
                requestData.tokens.length === 0 ? <Devices /> : null
            }
        </div>
    )
};

export default Stats;
