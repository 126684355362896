import React, { FC } from "react";
import { Link } from "react-router-dom";
import './ContentBlock.scss';

interface ContentBlockProps {
    title?: string;
    margin?: string;
    padding?: string,
    content?: React.ReactNode,
    isLink?: boolean,
    linkUrl?: string,
    linkName?: string
};

const ContentBlock: FC<ContentBlockProps> = ({ title, content, margin, padding = '32px 32px 40px', isLink = false, linkUrl = "/", linkName }) => {
    return (
        <div className="content-block" style={{margin: margin}}>
            <div className="content-block-title">
                <h3>{title}</h3>
                <div className="content-block-title-controls">
                    {isLink &&
                        <Link to={linkUrl} className="content-block-title-controls-link">{linkName}</Link>
                    }
                </div>
            </div>
            <div style={{ padding: padding }}>{content}</div>
        </div>
    );
};

export default ContentBlock;
