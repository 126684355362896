import React, { FC, useState, useEffect } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import './Notification.scss';

const Notification: FC = () => {
    const notificationData = useTypedSelector(state => state.notification.notification);
    const [isNotification, setIsNotification] = useState<boolean>(notificationData.isNotification);

    const { notification } = useActions();

    const notificationDefault = {
        isNotification: false,
        type: "",
        message: ""
    };

    useEffect((): void => {
        if (notificationData.isNotification === true) {
            setIsNotification(true);

            setTimeout(() => {
                setIsNotification(false);

                notification(notificationDefault);
            }, 3000);
        }

    }, [notificationData.isNotification])

    return (
        <React.Fragment>
            {isNotification &&
                <div className={`notification-${notificationData.type}`}>
                    <p
                        className={`notification-${notificationData.type}-text`}
                        dangerouslySetInnerHTML={{ __html: notificationData.message }}
                    />
                </div>
            }
        </React.Fragment>
    )
};

export default Notification;
