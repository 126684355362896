import { combineReducers } from "redux";
import { authReducer } from "./authReducer";
import { navbarItemsReducer } from "./navbarItemsReducer";
import { notificationReducer } from "./notificationReducer";
import { adminsReducer } from "./adminsReducer";
import { profilesReducer } from "./profilesReducer";
import { periodReducer } from "./periodReducer";
import { statsHighlightsReducer } from "./statsHighlightsReducer";
import { statsActivityReducer } from "./statsActivityReducer";
import { statsCategoriesReducer } from "./statsCategoriesReducer";
import { statsDomainsReducer } from "./statsDomainsReducer";
import { statsDetailedReducer } from "./statsDetailedReducer";
import { statsDevicesReducer } from "./statsDevicesReducer";
import { statsRequestDataReducer } from "./statsRequestDataReducer";
import { statsExportReducer } from "./statsExportReducer";
import { statsMonthlyReportReducer } from "./statsMonthlyReportReducer";
import { networksReducer } from "./networksReducer";
import { categoriesReducer } from "./categoriesReducer";
import { agentInfoReducer } from "./agentInfoReducer";
import { adUsersReducer } from "./adUsersReducer";
import { dataExportS3IntegrationReducer } from "./dataExport/s3IntegrationReducer";

export const rootReducer = combineReducers({
    auth: authReducer,
    navbar: navbarItemsReducer,
    admins: adminsReducer,
    profiles: profilesReducer,
    period: periodReducer,
    notification: notificationReducer,
    statsHighlights: statsHighlightsReducer,
    statsActivity: statsActivityReducer,
    statsCategories: statsCategoriesReducer,
    statsDomains: statsDomainsReducer,
    statsDevices: statsDevicesReducer,
    statsDetailed: statsDetailedReducer,
    statsRequestData: statsRequestDataReducer,
    statsExport: statsExportReducer,
    statsMonthlyReport: statsMonthlyReportReducer,
    networks: networksReducer,
    categories: categoriesReducer,
    agentInfo: agentInfoReducer,
    adUsers: adUsersReducer,
    dataExportS3Integration: dataExportS3IntegrationReducer
});

export type RootState = ReturnType<typeof rootReducer>;
