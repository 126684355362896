import React, { FC, useState, useEffect } from "react";
import './InfoBlockAccordion.scss';

interface InfoBlockAccordionProps {
    id?: string,
    title?: string;
    margin?: string;
    padding?: string,
    is_content?: boolean,
    content?: React.ReactNode
};

const InfoBlockAccordion: FC<InfoBlockAccordionProps> = ({ id, title, is_content = true, content, margin = '0', padding = '0' }) => {
    const [isActive, setIsActive] = useState<boolean>(true);
    const [isInfoIsViewed, setIsInfoIsViewed] = useState<any>(localStorage.getItem(`${id}_info-is_viewed`));

    const onToggleViewInfo = () => {
        setIsActive(!isActive);

        if (id) {
            localStorage.setItem(`${id}_info-is_viewed`, String(!isActive));
        }
    }

    useEffect(() => {
        if (isInfoIsViewed) {
            setIsInfoIsViewed(localStorage.getItem(`${id}_info-is_viewed`));
        } else {
            setIsInfoIsViewed(true);
        }
    }, [isActive, localStorage]);

    return (
        <div className="info-block-accordion" style={{margin: margin, padding: padding}}>
            <div className="info-block-accordion-head" onClick={onToggleViewInfo}>
                <h4 className="info-block-accordion-head-title">{title}</h4>
                {is_content && <span className={`info-block-accordion-${JSON.parse(isInfoIsViewed) ? "open" : "closed"}`}></span>}
            </div>
            {is_content &&
                JSON.parse(isInfoIsViewed) &&
                <div className="info-block-accordion-data">
                    {content}
                </div>
            }
        </div>
    );
};

export default InfoBlockAccordion;
