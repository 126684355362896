export enum StatsActivityActionsTypes {
    GET_STATS_ACTIVITY = "GET_STATS_ACTIVITY",
    GET_STATS_ACTIVITY_SUCCESS = "GET_STATS_ACTIVITY_SUCCESS",
    GET_STATS_ACTIVITY_FAILURE = "GET_STATS_ACTIVITY_FAILURE",
    STATS_ACTIVITY_REQUEST = "STATS_ACTIVITY_REQUEST",
    STATS_ACTIVITY_DATA_RESULT = "STATS_ACTIVITY_DATA_RESULT"
};

export interface IInitialState {
    statsActivity: string,
    loading: boolean,
    error: null | string,
    request: any,
    dataResult: any
};

interface IGetStatsActivity {
    type: StatsActivityActionsTypes.GET_STATS_ACTIVITY,
};

interface IGetStatsActivitySuccess {
    type: StatsActivityActionsTypes.GET_STATS_ACTIVITY_SUCCESS,
    payload: string
};

interface IGetStatsActivityFailure {
    type: StatsActivityActionsTypes.GET_STATS_ACTIVITY_FAILURE,
    payload: string
};

interface IStatsActivityRequest {
    type: StatsActivityActionsTypes.STATS_ACTIVITY_REQUEST,
    payload: any
};

interface IStatsActivityDataResult {
    type: StatsActivityActionsTypes.STATS_ACTIVITY_DATA_RESULT,
    payload: any
};

export type StatsActivityActions =
    IGetStatsActivity |
    IGetStatsActivitySuccess |
    IGetStatsActivityFailure |
    IStatsActivityRequest |
    IStatsActivityDataResult;
