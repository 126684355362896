import React, { FC } from "react";
import "./404.scss";

interface NotFoundProps {
    text: string;
};

const  NotFound: FC<NotFoundProps> = ({ text }) => {
    return (
        <div className="not-found">
            <h1 className="not-found-title">404</h1>
            <div className="not-found-text" dangerouslySetInnerHTML={{ __html: text }} />
        </div>
    )
};

export default NotFound;
