export enum AdUsersActionsTypes {
    GET_AD_USERS = "GET_AD_USERS",
    GET_AD_USERS_SUCCESS = "GET_AD_USERS_SUCCESS",
    GET_AD_USERS_FAILURE = "GET_AD_USERS_FAILURE",
};

export interface IAdUsersResponseItem {
    id: number|null,
    domain: number|null,
    hostname: string,
    last_seen: string,
    name: string,
    os_info: string,
    profile_id: number|null,
    token: number|null,
    comment: string
};

export interface IAdUsersResponse {
    next_page_link: number|null,
    previous_page_link: number|null,
    total_items: number|null,
    total_pages: number|null,
    items_per_page: number|null,
    page_number: number|null,
    results: IAdUsersResponseItem[]
};

export interface IInitialState {
    adUsers: IAdUsersResponse,
    loading: boolean,
    error: string|null,
};

interface IGetAdUsers {
    type: AdUsersActionsTypes.GET_AD_USERS,
};

interface IGetAdUsersSuccess {
    type: AdUsersActionsTypes.GET_AD_USERS_SUCCESS,
    payload: IAdUsersResponse
};

interface IGetAdUsersFailure {
    type: AdUsersActionsTypes.GET_AD_USERS_FAILURE,
    payload: string
};

export type AdUsersActions =
    IGetAdUsers |
    IGetAdUsersSuccess |
    IGetAdUsersFailure;
