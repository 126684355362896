import React, { FC } from "react";
import "./Paginator.scss"

type PaginatorGaps = {
    before: boolean;
    paginationGroup: any[];
    after: boolean;
};

interface PaginatorProps {
    nextPage: () => void;
    prevPage: () => void;
    page: number;
    gaps: PaginatorGaps;
    setPage: (num: number) => void;
    totalPages: number;
};

const Paginator: FC<PaginatorProps> = ({ nextPage, prevPage, page, gaps, setPage, totalPages }) => {
    return (
        <div className="paginator">
            <button
                onClick={prevPage}
                className={`previous ${page === 1 ? "disabled" : ""}`}
            />
            <button
                onClick={() => setPage(1)}
                className={`page ${page === 1 ? "active" : ""}`}
            >1
            </button>
            {gaps.before ? "..." : null}
            {gaps.paginationGroup.map((el: any) => (
                <button
                    onClick={() => setPage(el)}
                    key={el}
                    className={`page ${page === el ? "active" : ""}`}
                >{el}
                </button>
            ))}
            {gaps.after ? "..." : null}
            <button
                onClick={() => setPage(totalPages)}
                className={`page ${page === totalPages ? "active" : ""}`}
            >{totalPages}
            </button>
            <button
                onClick={nextPage}
                className={`next ${page === totalPages ? "disabled" : ""}`}
            />
        </div>
    );
};

export default Paginator;
