import { NotificationActions, NotificationActionsTypes, IInitialState } from "../../types/notification";

const initialState: IInitialState = {
    notification: {
        isNotification: false,
        type: "",
        message: ""
    }
};

export const notificationReducer = (state = initialState, action: NotificationActions): IInitialState => {
    switch (action.type) {
        case NotificationActionsTypes.NOTIFICATION:
            return {
                ...state,
                notification: action.payload
            }
        default:
            return state
    };
};
