import React, { FC } from "react";
import './Loader.scss';

interface LoaderProps {
    text?: string
};

const Loader: FC<LoaderProps> = ({ text }) => {
    return (
        <div className="loader">
            <span className='loader-icon'></span>
            {text && <p className='loader-text'>{text}</p>}
        </div>
    );
};

export default Loader;
