import { DataExportS3IntegrationActions, DataExportS3IntegrationActionsTypes, IInitialState } from "../../../types/dataExport/s3Integration";

const initialState: IInitialState = {
    s3IntegrationIsVerify: null,
    s3IntegrationVerifyRequestData: {
        endpoint: "",
        access_key: "",
        secret_key: "",
        bucket: "",
        prefix: "",
        include_headers: false
    },
    s3IntegrationCreateData: {
        id: "",
        user: "",
        endpoint: "",
        access_key: "",
        secret_key: "",
        bucket: "",
        prefix: "",
        is_valid: false,
        include_headers: false,
        last_export: ""
    },
    s3IntegrationUpdateData: {
        id: "",
        user: "",
        endpoint: "",
        access_key: "",
        secret_key: "",
        bucket: "",
        prefix: "",
        is_valid: false,
        include_headers: false,
        last_export: ""
    },
    s3IntegrationReadData: {
        id: "",
        user: "",
        endpoint: "",
        access_key: "",
        secret_key: "",
        bucket: "",
        prefix: "",
        is_valid: false,
        include_headers: false,
        last_export: ""
    },
    s3IntegrationDelete: null,
    loading: false,
    error: null,
};

export const dataExportS3IntegrationReducer = (state = initialState, action: DataExportS3IntegrationActions): IInitialState => {
    switch (action.type) {
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY:
            return {
                ...state,
                loading: true,
                error: null,
                s3IntegrationIsVerify: false
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                s3IntegrationIsVerify: action.payload
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                s3IntegrationIsVerify: false
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE:
            return {
                ...state,
                loading: true,
                error: null,
                s3IntegrationCreateData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                s3IntegrationCreateData: action.payload
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                s3IntegrationCreateData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE:
            return {
                ...state,
                loading: true,
                error: null,
                s3IntegrationUpdateData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                s3IntegrationUpdateData: action.payload
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                s3IntegrationUpdateData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ:
            return {
                ...state,
                loading: true,
                error: null,
                s3IntegrationReadData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                s3IntegrationReadData: action.payload
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                s3IntegrationReadData: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE:
            return {
                ...state,
                loading: true,
                error: null,
                s3IntegrationDelete: null
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                s3IntegrationDelete: action.payload
            }
        case DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                s3IntegrationDelete: null
            }
        default:
            return state
    };
};
