import { FC } from "react";
import DataExportStatus from "./DataExportStatus";
import ButtonLink from "../UI/button/ButtonLink";
import './DataExport.scss';

interface DataExportCardProps {
    id: string;
    title: string;
    description: string;
    isCompleted: boolean;
    status: string;
    lastExport: string;
};

const DataExportCard: FC<DataExportCardProps> = ({ id, title, description, isCompleted, status, lastExport }) => {

    const state = JSON.stringify({
        id: id,
        title: title,
        description: description,
        isCompleted: isCompleted,
        status: status,
        lastExport: lastExport
    });

    return (
        <div id={id} className="data-export-card">
            <div className="data-export-card-info">
                <h3 className="data-export-card-info-title">{title}</h3>

                {isCompleted &&
                    <DataExportStatus
                        status={status}
                        lastExport={lastExport}
                    />
                }

                <p className="data-export-card-info-description">{description}</p>
            </div>

            <div className="data-export-card-controls">
                {isCompleted ?
                    <ButtonLink
                        link={`/cabinet/new_statistics/data-export/check/${id}`}
                        name="Экспорт"
                        modifier="primary"
                        width="100%"
                        state={state}
                    /> :
                    <ButtonLink
                        link={`/cabinet/new_statistics/data-export/${id}`}
                        name="Экспорт"
                        modifier="primary"
                        width="100%"
                        state={state}
                    />
                }
            </div>
        </div>
    );
};

export default DataExportCard;
