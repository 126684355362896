import { FC, useEffect, useState, useContext } from "react";
import { useActions } from "../../../hooks/useActions";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useLocation, useNavigate } from "react-router-dom";
import { IS3IntegrationVerifyRequestData } from "../../../types/dataExport/s3Integration";
import ModalContext from "../../../contexts/modalContext";
import Input from "../../UI/input/Input";
import Toggler from "../../UI/toggler/Toggler";
import Button from "../../UI/button/Button";
import ButtonLink from "../../UI/button/ButtonLink";
import '../DataExport.scss';

interface IS3IntegrationDataProps {
    bucket: string,
    setBucket: (bucket: string) => void,
    keyPrefix: string,
    setKeyPrefix: (keyPrefix: string) => void,
    accessKeyId: string,
    setAccessKeyId: (accessKeyId: string) => void,
    endpoint: string,
    setEndpoint: (endpoint: string) => void,
    secretAccessKey: string,
    setSecretAccessKey: (secretAccessKey: string) => void,
    includeColumnHeaders: boolean,
    setIncludeColumnHeaders: (includeColumnHeaders: boolean) => void,
    formType: string
}

const S3DataExportForm: FC<IS3IntegrationDataProps> = ({
    bucket,
    setBucket,
    keyPrefix,
    setKeyPrefix,
    accessKeyId,
    setAccessKeyId,
    endpoint,
    setEndpoint,
    secretAccessKey,
    setSecretAccessKey,
    includeColumnHeaders ,
    setIncludeColumnHeaders,
    formType
}) => {
    const location = useLocation();
    const navigate = useNavigate();
    const state = JSON.parse(location.state);

    const { isShowS3IntegrationCompleteModal, setIsShowS3IntegrationCompleteModal } = useContext(ModalContext);

    const data = JSON.stringify({
        id: state.id,
        title: state.title,
        description: state.description,
        isCompleted: state.isCompleted,
        status: state.status,
        lastExport: state.lastExport
    });

    const {
        fetchS3IntegrationVerifyCheck,
        fetchS3IntegrationCreate,
        fetchS3IntegrationUpdate
    } = useActions();

    const isAuthData = useTypedSelector(state => state.auth.authData);
    const isVerify = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationIsVerify);
    const s3IntegrationCreate = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationCreateData);
    const s3IntegrationUpdate = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationUpdateData);
    const s3IntegrationData = useTypedSelector(state => state.dataExportS3Integration.s3IntegrationReadData);
    const loading = useTypedSelector(state => state.dataExportS3Integration.loading);

    const [verify, setVerify] = useState<boolean>(false);
    const [bucketError, setBucketError] = useState<string>("");
    const [keyPrefixError, setKeyPrefixError] = useState<string>("");
    const [endpointError, setEndpointError] = useState<string>("");
    const [accessKeyIdError, setAccessKeyIdError] = useState<string>("");
    const [secretAccessKeyError, setSecretAccessKeyError] = useState<string>("");

    const [formIsValid, setFormIsValid] = useState<boolean>(false);
    const [formIsSuccess, setFormIsSuccess] = useState<boolean>(false);

    const [integrationData, setIntegrationData] = useState<IS3IntegrationVerifyRequestData>({
        bucket: "",
        prefix: "",
        access_key: "",
        endpoint: "",
        secret_key: "",
        include_headers: false
    });

    useEffect(() => {
        bucketError || keyPrefixError || endpointError || accessKeyIdError || secretAccessKeyError ? setFormIsValid(false) : setFormIsValid(true)
    }, [bucketError, keyPrefixError, endpointError, accessKeyIdError, secretAccessKeyError]);


    useEffect(()=> {
        if (formType === "edit") {
            setBucket(s3IntegrationData ? s3IntegrationData.bucket : "");
            setKeyPrefix(s3IntegrationData ? s3IntegrationData.prefix : "");
            setAccessKeyId(s3IntegrationData ? s3IntegrationData.access_key : "");
            setEndpoint(s3IntegrationData ? s3IntegrationData.endpoint : "");
            setSecretAccessKey(s3IntegrationData ? s3IntegrationData.secret_key : "");
            setIncludeColumnHeaders(s3IntegrationData ? s3IntegrationData.include_headers : "");

            setIntegrationData({
                ...integrationData,
                bucket: bucket ? bucket : s3IntegrationData && s3IntegrationData.bucket,
                prefix: keyPrefix ? keyPrefix : s3IntegrationData && s3IntegrationData.prefix,
                access_key: accessKeyId ? accessKeyId : s3IntegrationData && s3IntegrationData.access_key,
                endpoint: endpoint ? endpoint : s3IntegrationData && s3IntegrationData.endpoint,
                secret_key: secretAccessKey ? secretAccessKey : s3IntegrationData && s3IntegrationData.secret_key,
                include_headers: includeColumnHeaders ? includeColumnHeaders : s3IntegrationData && s3IntegrationData.include_headers
            });
        }
    }, [s3IntegrationData]);

    const onIncludeColumnHeadersInExport = () => {
        setIncludeColumnHeaders(!includeColumnHeaders)
        setIntegrationData({ ...integrationData, include_headers: !includeColumnHeaders });
    };

    const onCreateForm = () => {
        fetchS3IntegrationCreate(isAuthData.user_id, integrationData);

        if (s3IntegrationCreate) {
            setIsShowS3IntegrationCompleteModal(!isShowS3IntegrationCompleteModal);
        }

        setBucket("");
        setAccessKeyId("");
        setKeyPrefix("");
        setEndpoint("");
        setSecretAccessKey("");

        setBucketError(" ");
        setKeyPrefixError(" ");
        setEndpointError(" ");
        setAccessKeyIdError(" ");
        setSecretAccessKeyError(" ");
    };

    const onEditForm = () => {
        fetchS3IntegrationUpdate(isAuthData.user_id, integrationData);

        if (s3IntegrationUpdate) {
            return navigate("/cabinet/new_statistics/data-export/check/s3", { state: data });
        }
    };

    const onVerify = () => {
        fetchS3IntegrationVerifyCheck(integrationData);
        setFormIsSuccess(true);
        setVerify(true);

        setTimeout(() => {
            setVerify(false);
        }, 3000);
    };

    const bucketHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setBucket(e.target.value);
        setFormIsSuccess(false);

        if (!e.target.value) {
            setBucketError("Поле «Корзина» обязательно для заполнения");
        } else {
            setBucketError("");
            setIntegrationData({ ...integrationData, bucket: e.target.value });
        }
    };

    const accessKeyIdHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setAccessKeyId(e.target.value);
        setFormIsSuccess(false);

        if (!e.target.value) {
            setAccessKeyIdError("Поле «Идентификатор ключа» обязательно для заполнения");
        } else {
            setAccessKeyIdError("");
            setIntegrationData({ ...integrationData, access_key: e.target.value });
        }
    };

    const keyPrefixHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setKeyPrefix(e.target.value);

        if (!e.target.value) {
            setKeyPrefixError("Поле «Ключевой префикс» обязательно для заполнения");
        } else {
            setKeyPrefixError("");
            setIntegrationData({ ...integrationData, prefix: e.target.value });
        }
    };

    const endpointHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setEndpoint(e.target.value);
        setFormIsSuccess(false);

        if (!e.target.value) {
            setEndpointError("Поле «Эндпойнт» обязательно для заполнения");
        } else {
            setEndpointError("");
            setIntegrationData({ ...integrationData, endpoint: e.target.value });
        }
    };

    const secretAccessKeyHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setSecretAccessKey(e.target.value);
        setFormIsSuccess(false);

        if (!e.target.value) {
            setSecretAccessKeyError("Поле «Секретный ключ» обязательно для заполнения");
        } else {
            setSecretAccessKeyError("");
            setIntegrationData({ ...integrationData, secret_key: e.target.value });
        }
    };

    return (
        <div className="data-export-settings-content">
            <Toggler
                name="Include column headers in export"
                changeHeandler={onIncludeColumnHeadersInExport}
                checked={includeColumnHeaders}
                label="Включить заголовки столбцов в экспорт"
            />

            <div className="data-export-settings-info">
                <h3>{state.title}</h3>
                <p>Служба SkyDNS попытается записать данные в корзину S3, предоставленную вашей компанией и управляемую ею. Задание на экспорт данных будет запускаться примерно каждые 10 минут. Пример файла для экспорта и дополнительные инструкции приведены в нашем руководстве.</p>
            </div>

            <div className="data-export-settings-form">
                <div className="data-export-settings-form-item">
                    <Input
                        type="text"
                        name="bucket"
                        width="512px"
                        value={bucket}
                        label="Корзина*"
                        placeholder="Введите номер корзины"
                        changeHeandler={e => bucketHandler(e)}
                    />
                    {bucketError &&
                        <span className="data-export-settings-form-item-error">{bucketError}</span>
                    }
                </div>
                <div className="data-export-settings-form-item">
                    <Input
                        type="text"
                        name="keyPrefix"
                        width="512px"
                        value={keyPrefix}
                        label="Ключевой префикс*"
                        placeholder="Введите ключевой префикс"
                        changeHeandler={e => keyPrefixHandler(e)}
                    />
                    {keyPrefixError &&
                        <span className="data-export-settings-form-item-error">{keyPrefixError}</span>
                    }
                </div>
                <div className="data-export-settings-form-item">
                    <Input
                        type="text"
                        name="endpoint"
                        width="512px"
                        value={endpoint}
                        label="Эндпойнт*"
                        placeholder="Введите эндпойнт"
                        changeHeandler={e => endpointHandler(e)}
                    />
                    {endpointError &&
                        <span className="data-export-settings-form-item-error">{endpointError}</span>
                    }
                </div>
                <div className="data-export-settings-form-item">
                    <Input
                        type="text"
                        name="accessKeyId"
                        width="512px"
                        value={accessKeyId}
                        label="Идентификатор ключа доступа*"
                        placeholder="Введите идентификатор ключа доступа"
                        changeHeandler={e => accessKeyIdHandler(e)}
                    />
                    {accessKeyIdError &&
                        <span className="data-export-settings-form-item-error">{accessKeyIdError}</span>
                    }
                </div>
                <div className="data-export-settings-form-item">
                    <Input
                        type="password"
                        name="secretAccessKey"
                        width="512px"
                        value={secretAccessKey}
                        label="Секретный ключ доступа*"
                        placeholder="Введите секретный ключ доступа"
                        changeHeandler={e => secretAccessKeyHandler(e)}
                    />
                    {secretAccessKeyError &&
                        <span className="data-export-settings-form-item-error">{secretAccessKeyError}</span>
                    }
                </div>
            </div>

            <div className="data-export-settings-info">
                <h3>Верификация и тестирование учетной записи</h3>
                <p>Чтобы завершить настройку, нашей системе необходимо будет протестировать информацию о подключении, введенную выше, и убедиться в наличии соответствующих привилегий учетной записи, позволяющих экспортировать данные. Мы выполним следующие тестовые операции:</p>
                <br />
                <p>1. Подключитесь, используя предоставленные вами учетные данные</p>
                <p>2. Запишите файл в пункт назначения</p>
            </div>

            <Button
                type="button"
                name="Проверка и тестирование"
                modifier="primary"
                width="200px"
                clickHeandler={onVerify}
                isDisabled={formIsValid ? false : true}
            />

            {verify && !loading &&
                <div className={`data-export-settings-verify-${isVerify ? 'complete' : 'failed'}`}>
                    <span>{isVerify ?
                        `Ваша учетная запись была успешно подтверждена. Выберите ${formType === "edit" ? "'Сохранить'" : "'Завершить'"} ниже, чтобы завершить настройку.` :
                        "Ваша учетная запись не была подтверждена. Пожалуйста, проверьте настройки."}
                    </span>
                </div>
            }

            <div className="data-export-settings-controls">
                {formType === "edit" ?
                    <ButtonLink
                        link="/cabinet/new_statistics/data-export/check/s3"
                        name="Назад"
                        modifier="secondary"
                        width="72px"
                        state={data}
                    /> :
                    <ButtonLink
                        link="/cabinet/new_statistics/data-export/"
                        name="Отменить"
                        modifier="secondary"
                        width="114px"
                    />
                }

                <div className="data-export-settings-controls">
                    {formType === "create" &&
                        <Button
                            type="button"
                            name="Завершить"
                            modifier="primary"
                            width="122px"
                            clickHeandler={onCreateForm}
                            isDisabled={isVerify ? false : true}
                        />
                    }
                    {formType === "edit" &&
                        <Button
                            type="button"
                            name="Сохранить"
                            modifier="primary"
                            width="100px"
                            clickHeandler={onEditForm}
                            isDisabled={isVerify && formIsSuccess ? false : true}
                        />
                    }
                </div>
            </div>
        </div>
    );
};

export default S3DataExportForm;
