import { StatsExportActions, StatsExportActionsTypes, IInitialState } from "../../types/statsExport";

const initialState: IInitialState = {
    statsExport: [],
    loading: false,
    error: null,
};

export const statsExportReducer = (state = initialState, action: StatsExportActions): IInitialState => {
    switch (action.type) {
        case StatsExportActionsTypes.STATS_EXPORT:
            return {
                ...state,
                loading: true,
                error: null,
                statsExport: []
            }
        case StatsExportActionsTypes.STATS_EXPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsExport: action.payload
            }
        case StatsExportActionsTypes.STATS_EXPORT_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsExport: []
            }
        default:
            return state
    };
};
