import React, { FC } from "react";
import "./Button.scss";

interface ButtonProps {
    type: React.ButtonHTMLAttributes<HTMLButtonElement>["type"];
    name?: string;
    modifier: string;
    width?: string;
    isDisabled?: boolean;
    clickHeandler: React.MouseEventHandler<HTMLButtonElement>;
};

const Button: FC<ButtonProps> = ({ type, name, modifier, width, isDisabled, clickHeandler }) => {
    return (
        <button
            type={type}
            className={`button-${modifier}`}
            style={{width: width}}
            disabled={isDisabled}
            onClick={clickHeandler}
        >{name}
        </button>
    );
};

export default Button;
