import React, { FC } from "react";
import "./Toggler.scss";

interface TogglerProps {
    id?: string;
    name?: string;
    label?: string;
    isDisabled?: boolean;
    changeHeandler: React.ChangeEventHandler<HTMLInputElement>;
    isDefaultChecked?: boolean;
    checked?: boolean;
    direction?: any;
};

const Toggler: FC<TogglerProps> = ({ id, name, label, changeHeandler, isDisabled, isDefaultChecked, checked, direction = "row" }) => {
    return (
        <div className="toggler-wrapper" style={{ flexDirection: direction }}>
            <label className="toggler">
                <input
                    id={id}
                    type="checkbox"
                    name={name}
                    onChange={changeHeandler}
                    disabled={isDisabled}
                    defaultChecked={isDefaultChecked}
                    checked={checked}
                />
                <span className="toggler-slider round"></span>
            </label>
            {label &&
                <span className="toggler-label">{label}</span>
            }
        </div>
    );
};

export default Toggler;
