import React, { FC } from "react";
import './Footer.scss';

const Footer: FC = () => {
    const thisYear = new Date().getFullYear();

    return (
        <React.Fragment>
            <footer className="footer">
                <div className="footer-content">
                    <div className="footer-content-info">
                        <p>© {thisYear} SkyDNS - Все права защищены</p>
                    </div>
                    <div className="footer-content-social">
                        <a className="footer-content-social-vk" href="https://vk.com/skydns" target="_blank" rel="noreferrer"> </a>
                    </div>
                </div>
            </footer>
        </React.Fragment>
    )
};

export default Footer;
