import { Dispatch } from "redux";
import {
    DataExportS3IntegrationActions,
    DataExportS3IntegrationActionsTypes,
    IS3IntegrationVerifyRequestData,
} from "../../../types/dataExport/s3Integration";
import { s3IntegrationVerifyCheck, s3IntegrationCreate, s3IntegrationRead, s3IntegrationUpdate, s3IntegrationDelete } from "../../../api";


export const fetchS3IntegrationVerifyCheck = (data: IS3IntegrationVerifyRequestData) =>
    async (dispatch: Dispatch<DataExportS3IntegrationActions>): Promise<any> => {
        dispatch({
            type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY,
        });

        try {
            const response = await s3IntegrationVerifyCheck(data);

            if (response.status === 204) {
                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_SUCCESS,
                    payload: true
                });
            }
        } catch (error: any) {
            dispatch({
                type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_SUCCESS,
                payload: false
            });
        }
    }

export const fetchS3IntegrationCreate = (user_id: number | string, data: IS3IntegrationVerifyRequestData) =>
    async (dispatch: Dispatch<DataExportS3IntegrationActions>): Promise<any> => {
        dispatch({
            type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE,
        });

        try {
            const response = await s3IntegrationCreate(user_id, data);

            if (response.status === 201) {
                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_SUCCESS,
                    payload: null
                });

                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error: any) {
            dispatch({
                type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_FAILURE,
                payload: "error"
            });
        }
    }

export const fetchS3IntegrationUpdate = (user_id: number | string, data: IS3IntegrationVerifyRequestData) =>
    async (dispatch: Dispatch<DataExportS3IntegrationActions>): Promise<any> => {
        dispatch({
            type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE,
        });

        try {
            const response = await s3IntegrationUpdate(user_id, data);

            if (response.status === 200) {
                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error: any) {
            dispatch({
                type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_FAILURE,
                payload: "error"
            });
        }
    }

export const fetchS3IntegrationRead = (user_id: number | string) =>
    async (dispatch: Dispatch<DataExportS3IntegrationActions>): Promise<any> => {
        dispatch({
            type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ,
        });

        try {
            const response = await s3IntegrationRead(user_id);

            if (response.status === 200) {
                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error: any) {
            dispatch({
                type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_FAILURE,
                payload: "error"
            });
        }
    }

export const fetchS3IntegrationDelete = (user_id: number | string) =>
    async (dispatch: Dispatch<DataExportS3IntegrationActions>): Promise<any> => {
        dispatch({
            type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE,
        });

        try {
            const response = await s3IntegrationDelete(user_id);

            if (response.status === 204) {
                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_SUCCESS,
                    payload: null
                });

                dispatch({
                    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_SUCCESS,
                    payload: response.data
                });
            }
        } catch (error: any) {
            dispatch({
                type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_FAILURE,
                payload: "error"
            });
        }
    }
