import { FC, useState } from "react";
import Navigation from "../../controls/Navigation";
import ContentBlock from "../../UI/content-block/ContentBlock";
import S3DataExportForm from "./S3DataExportForm";
import '../DataExport.scss';

const S3DataExportSettings: FC = () => {
    const [includeColumnHeaders, setIncludeColumnHeaders] = useState<boolean>(false);
    const [bucket, setBucket] = useState<string>("");
    const [accessKeyId, setAccessKeyId] = useState<string>("");
    const [keyPrefix, setKeyPrefix] = useState<string>("");
    const [endpoint, setEndpoint] = useState<string>("");
    const [secretAccessKey, setSecretAccessKey] = useState<string>("");

    return (
        <div className="data-export-settings">
            <div className="controls">
                <Navigation />
            </div>
            <ContentBlock
                title={"Настройки"}
                padding="0"
                content={
                    <S3DataExportForm
                        bucket={bucket}
                        setBucket={setBucket}
                        keyPrefix={keyPrefix}
                        setKeyPrefix={setKeyPrefix}
                        accessKeyId={accessKeyId}
                        setAccessKeyId={setAccessKeyId}
                        endpoint={endpoint}
                        setEndpoint={setEndpoint}
                        secretAccessKey={secretAccessKey}
                        setSecretAccessKey={setSecretAccessKey}
                        includeColumnHeaders={includeColumnHeaders}
                        setIncludeColumnHeaders={setIncludeColumnHeaders}
                        formType={"create"}
                    />
                }
            />
       </div>
    );
};

export default S3DataExportSettings;
