export const checkCount = (value: any) => {
    const formatter = new Intl.NumberFormat('en-US');

    if (!value) {
        return 0
    }

    if (value > 1000000000) {
        return (value / 1000000000).toFixed(3).toString().replace(".",",") + 'B'
    }

    return formatter.format(value);
};
