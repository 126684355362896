import { NavbarItemsActions, NavbarItemsActionsTypes, IInitialState } from "../../types/navbarItems";

const initialState: IInitialState = {
    navbarItems: [],
    loading: false,
    error: null,
};

export const navbarItemsReducer = (state = initialState, action: NavbarItemsActions): IInitialState => {
    switch (action.type) {
        case NavbarItemsActionsTypes.GET_NAVBAR_ITEMS:
            return {
                ...state,
                loading: true,
                error: null,
                navbarItems: []
            }
        case NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                navbarItems: action.payload
            }
        case NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                navbarItems: []
            }
        default:
            return state
    };
};
