import { NetworksActions, NetworksActionsTypes, IInitialState } from "../../types/networks";

const initialState: IInitialState = {
    networks: {
        next_page_link: null,
        previous_page_link: null,
        total_items: null,
        total_pages: null,
        items_per_page: null,
        page_number: null,
        results: [{
            id: null,
            type: "",
            ipv4: "",
            ipv6: "",
            hostname: "",
            profile_id: null,
            comment: ""
        }]
    },
    loading: false,
    error: null,
};

export const networksReducer = (state = initialState, action: NetworksActions): IInitialState =>  {
    switch (action.type) {
        case NetworksActionsTypes.GET_NETWORKS:
            return {
                ...state,
                loading: true,
                error: null,
                networks: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        type: "",
                        ipv4: "",
                        ipv6: "",
                        hostname: "",
                        profile_id: null,
                        comment: ""
                    }]
                }
            }
        case NetworksActionsTypes.GET_NETWORKS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                networks: action.payload
            }
        case NetworksActionsTypes.GET_NETWORKS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                networks: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        type: "",
                        ipv4: "",
                        ipv6: "",
                        hostname: "",
                        profile_id: null,
                        comment: ""
                    }]
                }
            }
        default:
            return state
    };
};
