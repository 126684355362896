import React, { FC } from "react";

const DomainsInfo: FC = () => {
    return (
        <div className="stats-domains-info">
            <div className="stats-domains-info-item-allowed">
                <div>
                    <h5 className="stats-domains-info-item-allowed-title">Разрешенный домен</h5>
                    <p className="stats-domains-info-item-allowed-text">Запросы, прошедшие через веб-фильтр, поскольку наши алгоритмы не обнаружили киберугроз или ограничений в категориях вашей учетной записи или настройках домена.</p>
                </div>
            </div>
            <div className="stats-domains-info-item-blocked">
                <div>
                    <h5 className="stats-domains-info-item-blocked-title">Заблокированный домен</h5>
                    <p className="stats-domains-info-item-blocked-text">Запросы включают в себя случаи, которые наши алгоритмы идентифицировали как вредоносные или которые связаны с заблокированными категориями в настройках вашей учетной записи.</p>
                </div>
            </div>
            <div className="stats-domains-info-item-mixed">
                <div>
                    <h5 className="stats-domains-info-item-mixed-title">Смешенный домен</h5>
                    <p className="stats-domains-info-item-mixed-text">Домены с двойным статусом в течение периода — заблокированные и разрешенные или измененные категории, включая те, в которых были выявлены вредоносные признаки во время обновлений.</p>
                </div>
            </div>
        </div>
    )
};

export default DomainsInfo;
