import { FC } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { renderDeviceName } from '../../stats/devices/DeviceItemName';
import usePagination from '../../../hooks/usePagination';
import useSortable from '../../../hooks/useSortable';
import '../../stats/devices/Devices.scss';
import '../PdfReport.scss';

const PageSeven: FC = () => {
    const deviceList = useTypedSelector(state => state.statsDevices.deviceList);
    const devicesDataResult = useTypedSelector(state => state.statsDevices.dataResult);

    const loading = useTypedSelector(state => state.statsDevices.loading);
    const error = useTypedSelector(state => state.statsDevices.error);

    const formatter = new Intl.NumberFormat('en-US');

    //sorted devices to requests
    let { result, requestSort, sortConfig } = useSortable(devicesDataResult);

    const sortedDirectionClass = (name: string) => {
        if (!sortConfig) {
            return;
        }
        return sortConfig.key === name ? sortConfig.direction : undefined;
    };

    //pagination
    const itemsCount = 10;
    const {
        firstContentIndex,
        lastContentIndex,
    } = usePagination({contentPerPage: itemsCount, count: result.length});

    return (
        <div className="stats-devices-report">
            <h2 className="stats-devices-report-title">Устройства</h2>

            <div className="stats-devices-report-data">
                <div>
                    <ul className="stats-devices-list-header">
                        <li>УСТРОЙСТВО</li>
                        <li>ЗАПРОСЫ</li>
                        <li>ЗАБЛОКИРОВАННЫЕ ЗАПРОСЫ</li>
                    </ul>

                    {!error && devicesDataResult.length === 0 ?
                        <div className="stats-devices-list-empty">
                            <p>У вас пока нет статистики</p>
                        </div> :
                        <div className="stats-devices-list">
                            {result.length ? result
                                .slice(firstContentIndex, lastContentIndex)
                                .sort((a,b) => b.blocks - a.blocks)
                                .map((item, index) => (
                                    item.requests &&
                                    <div key={index} className="stats-devices-list">
                                        <ul className="stats-devices-list-item">
                                            <li className="stats-devices-list-item-domains">{renderDeviceName(deviceList, item)}</li>
                                            <li>{formatter.format(item.requests)}</li>
                                            <li>{formatter.format(item.blocks)}</li>
                                        </ul>
                                    </div>
                                )) :
                                <div className="stats-devices-list-empty">
                                    <p>По вашему запросу нет полезных результатов</p>
                                </div>
                            }
                        </div>
                    }
                </div>
            </div>

            <p className="stats-devices-report-info-title">Подробный отчет о 10 самых активных устройствах.</p>
        </div>
    )
};

export default PageSeven;
