import React, { FC, useState, useEffect } from "react";
import "./DropDownMenu.scss";

interface DropDownMenuProps {
    name?: string;
    variant?: string;
    content: React.ReactNode;
    toggler?: React.ReactNode;
    closeTrigger?: boolean;
    textColor?: string;
};

const DropDownMenu: FC<DropDownMenuProps> = ({ name, variant = "primary", content, toggler, textColor, closeTrigger }) => {
    const [isShowDropdownMenu, setIsShowDropdownMenu] = useState(true);

    useEffect(() => {
        setIsShowDropdownMenu(false);
    }, [closeTrigger])

    return (
        <div className={`dropdown-menu dropdown-menu-${variant}`}>
            {toggler}
            <button
                type="button"
                className="dropdown-menu-open"
                style={{ color: textColor }}
                onClick={()=> setIsShowDropdownMenu(!isShowDropdownMenu)}
            >
                {name}
            </button>
            {isShowDropdownMenu &&
                <>
                    <div
                        className="dropdown-menu-backdrop"
                        onClick={()=> setIsShowDropdownMenu(!isShowDropdownMenu)}>
                    </div>
                    <div className="dropdown-menu-list">
                        {content}
                    </div>
                </>
            }
        </div>
    );
};

export default DropDownMenu;
