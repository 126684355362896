import { StatsMonthlyReportActions, StatsMonthlyReportActionsTypes, IInitialState } from "../../types/statsMonthlyReport";

const initialState: IInitialState = {
    checkRecipientsList: [],
    recipientsList: [],
    recipientsRequestData: {
        recipients: [],
        file_types: [],
        period: "month",
        is_active: false
    },
    loading: false,
    error: null,
};

export const statsMonthlyReportReducer = (state = initialState, action: StatsMonthlyReportActions): IInitialState => {
    switch (action.type) {
        case StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST:
            return {
                ...state,
                loading: true,
                error: null,
                checkRecipientsList: []
            }
        case StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                checkRecipientsList: [action.payload]
            }
        case StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                checkRecipientsList: []
            }
        case StatsMonthlyReportActionsTypes.RECIPIENTS_LIST:
            return {
                ...state,
                loading: true,
                error: null,
                recipientsList: []
            }
        case StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                recipientsList: [action.payload]
            }
        case StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                recipientsList: []
            }
        default:
            return state
    };
};
