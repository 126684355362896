import { StatsActivityActions, StatsActivityActionsTypes, IInitialState } from "../../types/statsActivity";

const initialState: IInitialState = {
    statsActivity: "",
    loading: false,
    error: null,
    request: null,
    dataResult: null
};

export const statsActivityReducer = (state = initialState, action: StatsActivityActions): IInitialState => {
    switch (action.type) {
        case StatsActivityActionsTypes.GET_STATS_ACTIVITY:
            return {
                ...state,
                loading: true,
                error: null,
                statsActivity: ""
            }
        case StatsActivityActionsTypes.GET_STATS_ACTIVITY_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsActivity: action.payload
            }
        case StatsActivityActionsTypes.GET_STATS_ACTIVITY_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsActivity: ""
            }
        case StatsActivityActionsTypes.STATS_ACTIVITY_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsActivityActionsTypes.STATS_ACTIVITY_DATA_RESULT:
            return {
                ...state,
                dataResult: action.payload
            }
        default:
            return state
    };
};
