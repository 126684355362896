const Endpoints = {
    AUTH: {
        ACCESS: "/api/token/by-session/",
        REFRESH: "/api/token/refresh/",
        PROFILE: "/auth/is_authenticate"
    },
    STATS: {
        HIGHLIGHTS: "/api/rest/v2/get_total_activity/",
        CATEGORIES: "/api/rest/v2/get_categories_activity/",
        ACTIVITY: "/api/rest/v2/get_activity/",
        DOMAINS: "/api/rest/v2/get_domains_activity/",
        DEVICES: "/api/rest/v2/get_devices_activity/",
        DETAILED: "/api/rest/v2/get_detailed_activity/"
    }
};

export default Endpoints;
