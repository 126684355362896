import axios from "axios";
import { Dispatch } from "redux";
import { IAuthResponseJwt, AuthActions, AuthActionsTypes } from "../../types/auth";

export const checkAccessToken = () => {
    return function (dispatch: Dispatch<AuthActions>) {
        dispatch({
            type: AuthActionsTypes.GET_AUTH_TOKEN
        });

        axios.post<IAuthResponseJwt>(`${process.env.REACT_APP_BASE_URL}/api/token/by-session/`, {},
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_TOKEN_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_TOKEN_FAILURE,
                payload: 'error'
            });
        })
    };
};

export const refreshToken = (refresh: string|null) => {
    return function (dispatch: Dispatch<AuthActions>) {
        dispatch({
            type: AuthActionsTypes.GET_AUTH_TOKEN
        });

        axios.post<IAuthResponseJwt>(`${process.env.REACT_APP_BASE_URL}/api/token/refresh/`,
        {
            refresh: refresh
        },
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_TOKEN_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_TOKEN_FAILURE,
                payload: 'error'
            });
        })
    };
};

export const checkRefreshToken = (accessToken: any) => {
    if (!accessToken) {
        return true
    }

    try {
        const tokenInfo = JSON.parse(atob(accessToken.split('.')[1]));
        const { exp } = tokenInfo;
        const tokenLifeTime = exp - 30;
        const getUnixTime = Math.round((new Date().getTime() + 1) / 1000);

        return tokenLifeTime < getUnixTime
    } catch (error) {
        return true
    }
};

export const getPermissionsByFeatures = (accessToken: string|null) => (
    {
        type: AuthActionsTypes.AUTH_PERMISSIONS_BY_FEATURES,
        payload: accessToken
    }
);
