import React, { FC } from "react";
import "./Tooltip.scss";

interface TooltipProps {
    text: string;
    width?: string;
};

const Tooltip: FC<TooltipProps> = ({ text, width }) => {
    return (
        <div className="tooltip">
            <div className="tooltip-message">
                <div className="tooltip-message-text" dangerouslySetInnerHTML={{ __html: text }} style={{ width: width }} />
            </div>
        </div>
    )
};

export default Tooltip;
