import { FC } from "react";
import './DataExport.scss';

interface DataExportStatusProps {
    status: string;
    lastExport: string;
};

const DataExportStatus: FC<DataExportStatusProps> = ({ status, lastExport }) => {
    return (
        <div className="data-export-card-info-status">
            <div className="data-export-card-info-status-row">
                <p className="data-export-card-info-status-row-title">Статус</p>
                <span className="data-export-card-info-status-row-status">{status}</span>
            </div>
            <div className="data-export-card-info-status-row">
                <p className="data-export-card-info-status-row-title">Последний экспорт</p>
                <span className="data-export-card-info-status-row-date">{lastExport ? new Date(lastExport).toISOString().substring(0, 10) : "Н/Д"}</span>
            </div>
        </div>
    );
};

export default DataExportStatus;
