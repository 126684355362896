import { StatsHighlightsActions, StatsHighlightsActionsTypes, IInitialState } from "../../types/statsHighlights";

const initialState: IInitialState = {
    statsHighlights: "",
    loading: false,
    error: null,
    request: null,
    dataResult: null
};

export const statsHighlightsReducer = (state = initialState, action: StatsHighlightsActions): IInitialState => {
    switch (action.type) {
        case StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS:
            return {
                ...state,
                loading: true,
                error: null,
                statsHighlights: ""
            }
        case StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsHighlights: action.payload
            }
        case StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsHighlights: ""
            }
        case StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_DATA_RESULT:
            return {
                ...state,
                dataResult: action.payload
            }
        default:
            return state
    };
};
