import axios from "axios";
import { Dispatch } from "redux";
import { IAuthData, AuthActions, AuthActionsTypes } from "../../types/auth";

export const fetchAuthSession = () => {
    return function (dispatch: Dispatch<AuthActions>) {
        dispatch({
            type: AuthActionsTypes.GET_AUTH
        });

        axios.get<IAuthData>(`${process.env.REACT_APP_BASE_URL}/auth/rest_api/v1/users/is_authenticate/`,
        {
            withCredentials: true,
        })
        .then((response) => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: AuthActionsTypes.GET_AUTH_SUCCESS,
                payload: {
                    is_authenticated: false,
                    username: "",
                    user_id: "",
                    plan: "",
                    date_end: "",
                    language: "",
                    permissions: {
                        is_staff: false,
                        is_superuser: false
                    }
                }
            });
        })
    };
};
