import React, { FC } from "react";
import './ProgressLoader.scss';

const ProgressLoader: FC = () => {
    return (
        <div className="progress-loader">
            <div className="progress-loader-bar" style={{ width: '100%' }}></div>
        </div>
    );
};

export default ProgressLoader;
