import axios from "axios";
import { Dispatch } from "redux";
import { NavbarItemsActions, NavbarItemsActionsTypes } from "../../types/navbarItems";

export const fetchNavbarItems = (user_id: string|number) => {
    return function (dispatch: Dispatch<NavbarItemsActions>) {
        dispatch({
            type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS
        });

        axios.get(`${process.env.REACT_APP_BASE_URL}/content/rest_api/v1/users/${user_id}/cabinet_menu/`,
        {
            withCredentials: true
        })
        .then((response) => {
            dispatch({
                type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_SUCCESS,
                payload: response.data
            });
        })
        .catch(() => {
            dispatch({
                type: NavbarItemsActionsTypes.GET_NAVBAR_ITEMS_FAILURE,
                payload: 'error'
            });
        })
    };
};
