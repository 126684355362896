import React, { useState } from "react";

const NavbarContext = React.createContext();

export const NavbarProvider = ({ children }) => {
    const [isShowNavbar, setIsShowNavbar] = useState(false);

    return (
        <NavbarContext.Provider value={{ isShowNavbar, setIsShowNavbar }}>
            {children}
        </NavbarContext.Provider>
    );
};

export default NavbarContext;
