export enum StatsHighlightsActionsTypes {
    GET_STATS_HIGHLIGHTS = "GET_STATS_HIGHLIGHTS",
    GET_STATS_HIGHLIGHTS_SUCCESS = "GET_STATS_HIGHLIGHTS_SUCCESS",
    GET_STATS_HIGHLIGHTS_FAILURE = "GET_STATS_HIGHLIGHTS_FAILURE",
    STATS_HIGHLIGHTS_REQUEST = "STATS_HIGHLIGHTS_REQUEST",
    STATS_HIGHLIGHTS_DATA_RESULT = "STATS_HIGHLIGHTS_DATA_RESULT"
};

export interface IInitialState {
    statsHighlights: string,
    loading: boolean,
    error: null | string,
    request: any,
    dataResult: any
};

interface IGetStatsHighlights {
    type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS,
};

interface IGetStatsHighlightsSuccess {
    type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_SUCCESS,
    payload: string
};

interface IGetStatsHighlightsFailure {
    type: StatsHighlightsActionsTypes.GET_STATS_HIGHLIGHTS_FAILURE,
    payload: string
};

interface IStatsHighlightsRequest {
    type: StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_REQUEST,
    payload: any
};

interface IStatsHighlightsDataResult {
    type: StatsHighlightsActionsTypes.STATS_HIGHLIGHTS_DATA_RESULT,
    payload: any
};

export type StatsHighlightsActions =
    IGetStatsHighlights |
    IGetStatsHighlightsSuccess |
    IGetStatsHighlightsFailure |
    IStatsHighlightsRequest |
    IStatsHighlightsDataResult;
