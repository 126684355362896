export enum StatsMonthlyReportActionsTypes {
    CHECK_RECIPIENTS_LIST = "CHECK_RECIPIENTS_LIST",
    CHECK_RECIPIENTS_LIST_SUCCESS = "CHECK_RECIPIENTS_LIST_SUCCESS",
    CHECK_RECIPIENTS_LIST_FAILURE = "CHECK_RECIPIENTS_LIST_FAILURE",
    RECIPIENTS_LIST = "RECIPIENTS_LIST",
    RECIPIENTS_LIST_SUCCESS = "RECIPIENTS_LIST_SUCCESS",
    RECIPIENTS_LIST_FAILURE = "RECIPIENTS_LIST_FAILURE",
    RECIPIENTS_REQUEST_DATA = "RECIPIENTS_REQUEST_DATA"
};

export interface IInitialState {
    checkRecipientsList: IReportResponseData[],
    recipientsList: IReportResponseData[],
    recipientsRequestData: IReportRequestData,
    loading: boolean,
    error: null | string,
};

export interface IReportRequestData {
    recipients: any[],
    file_types: any[],
    period: string,
    is_active: boolean
};

export interface IReportResponseData {
    id: string|number,
    last_sent: any,
    user: string|number,
    recipients: number[],
    file_types: string[],
    period: string,
    is_active: boolean
}

interface ICheckRecipientsList {
    type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST,
};

interface ICheckRecipientsListSuccess {
    type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_SUCCESS,
    payload: IReportResponseData
};

interface ICheckRecipientsListFailure {
    type: StatsMonthlyReportActionsTypes.CHECK_RECIPIENTS_LIST_FAILURE,
    payload: string
};

interface IRecipientsList {
    type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST,
};

interface IRecipientsListSuccess {
    type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_SUCCESS,
    payload: IReportResponseData
};

interface IRecipientsListFailure {
    type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_FAILURE,
    payload: string
};

interface IRecipientsListFailure {
    type: StatsMonthlyReportActionsTypes.RECIPIENTS_LIST_FAILURE,
    payload: string
};

interface IRecipientsData {
    type: StatsMonthlyReportActionsTypes.RECIPIENTS_REQUEST_DATA,
    payload: IReportRequestData
};

export type StatsMonthlyReportActions =
    ICheckRecipientsList |
    ICheckRecipientsListSuccess |
    ICheckRecipientsListFailure |
    IRecipientsList |
    IRecipientsListSuccess |
    IRecipientsListFailure |
    IRecipientsData;
