import React, { FC, useState } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { IAccountData } from "../../types/account";
import ContentBlock from "../UI/content-block/ContentBlock";
import Input from "../UI/input/Input";
import Button from "../UI/button/Button";
import './Account.scss';

const Account: FC = () => {
    const isAuthData = useTypedSelector(state => state.auth.authData);
    const [firstName, setFirstName] = useState<string>("");
    const [lastName, setLastName] = useState<string>("");
    const [company, setCompany] = useState<string>("");
    const [phone, setPhone] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [formIsSuccess, setFormIsSuccess] = useState<boolean>(false);
    const [controlsIsVisible, setControlsIsVisible] = useState<boolean>(false);
    const [accountData, setAccountData] = useState<IAccountData>({
        id: isAuthData.user_id,
        username: isAuthData.username,
        firstName: '',
        lastName: '',
        company: '',
        phone: '',
        oldPassword: '',
        newPassword: ''
    });

    const firstNameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setFirstName(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, firstName: e.target.value });
        }
    };

    const lastNameHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setLastName(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, lastName: e.target.value });
        }
    };

    const companyHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setCompany(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, company: e.target.value });
        }
    };

    const phoneHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setPhone(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, phone: e.target.value });
        }
    };

    const oldPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setOldPassword(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, oldPassword: e.target.value });
        }
    };

    const newPasswordHandler = (e: React.ChangeEvent<HTMLInputElement>): void => {
        setNewPassword(e.target.value);
        setControlsIsVisible(true);

        if (e.target.value) {
            setAccountData({ ...accountData, newPassword: e.target.value });
        }
    };

    const cancelСhanges = (): void => {
        setFirstName("");
        setLastName("");
        setCompany("");
        setPhone("");
        setOldPassword("");
        setNewPassword("");

        setControlsIsVisible(false);
    };

    const formSend = (): void => {
        setFormIsSuccess(!formIsSuccess);
        setControlsIsVisible(false);
    };

    return (
        <div className="account">
            <ContentBlock
                title={"Personal data"}
                margin="40px 0 0"
                content={
                    <div className="account-data">
                        <div className="account-data-info">
                            <h3>Account</h3>
                            <span>{isAuthData.username}</span>
                        </div>

                        <div className="account-data-controls-col">
                            <div className="account-data-controls-col-item">
                                <Input
                                    type="text"
                                    name="firstName"
                                    width="470px"
                                    value={firstName}
                                    label="First name"
                                    changeHeandler={e => firstNameHandler(e)}
                                />
                            </div>
                            <div className="account-data-controls-col-item">
                                <Input
                                    type="text"
                                    name="lastName"
                                    width="470px"
                                    value={lastName}
                                    label="Last name"
                                    changeHeandler={e => lastNameHandler(e)}
                                />
                            </div>
                            <div className="account-data-controls-col-item">
                                <Input
                                    type="text"
                                    name="company"
                                    width="470px"
                                    value={company}
                                    label="Company"
                                    changeHeandler={e => companyHandler(e)}
                                />
                            </div>
                            <div className="account-data-controls-col-item">
                                <Input
                                    type="tel"
                                    name="phone"
                                    width="470px"
                                    value={phone}
                                    label="Phone number"
                                    changeHeandler={e => phoneHandler(e)}
                                />
                            </div>
                        </div>
                    </div>
                }
            />
            <ContentBlock
                title={"Password"}
                content={
                    <div className="account-data">
                        <div className="account-data-controls-row">
                            <div className="account-data-controls-row-item">
                                <Input
                                    type="password"
                                    name="oldPassword"
                                    width="264px"
                                    value={oldPassword}
                                    label="Old password"
                                    changeHeandler={e => oldPasswordHandler(e)}
                                />
                            </div>
                            <div className="account-data-controls-row-item">
                                <Input
                                    type="password"
                                    name="newPassword"
                                    width="264px"
                                    value={newPassword}
                                    label="New password"
                                    changeHeandler={e => newPasswordHandler(e)}
                                />
                            </div>
                            <div className="account-data-controls-row-item">
                                <p>The password must contain at least 6 characters, including numbers and  letters</p>
                            </div>
                        </div>

                        {controlsIsVisible &&
                            <div className="account-data-controls-row-buttons">
                                <Button
                                    type="button"
                                    name="Save changes"
                                    modifier="primary"
                                    width="188px"
                                    clickHeandler={formSend}
                                />
                                <Button
                                    type="button"
                                    name="Cancel"
                                    modifier="secondary"
                                    width="188px"
                                    clickHeandler={cancelСhanges}
                                />
                            </div>
                        }
                    </div>
                }
            />
        </div>
    );
};

export default Account;
