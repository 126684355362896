import React, { FC, useState } from "react";
import './Stats.scss';

interface IUpdateStats {
    updateStats: any,
    lastUpdate: string,
    disabledTimeout: number
};

const UpdateStatsButton: FC<IUpdateStats> = ({ updateStats, lastUpdate, disabledTimeout }) => {
    const [updateStatsDisabled, setUpdateStatsDisabled] = useState<boolean>(false);
    const onUpdateStats = () => {
        setUpdateStatsDisabled(true);
        updateStats();

        setTimeout(() => {
            setUpdateStatsDisabled(false)
        }, disabledTimeout);
    };

    return (
        <div className="stats-data-update">
            <button
                type='button'
                className="stats-data-update-button"
                onClick={() => onUpdateStats()}
                disabled={updateStatsDisabled}
            />
            <span>{`обновлено ${lastUpdate}`}</span>
        </div>
    )
};

export default UpdateStatsButton;
