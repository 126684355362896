import React, { FC, useContext } from "react";
import SlidingMenuContext from "../../../contexts/slidingMenuContext";
import Button from "../button/Button";
import "./SlidingMenu.scss";

interface SlidingMenuProps {
    title?: string;
    content?: React.ReactNode;
    footer?: React.ReactNode;
};

const SlidingMenu: FC<SlidingMenuProps> = ({ title, content, footer }) => {
    const { isShowSlidingMenu, setIsShowSlidingMenu } = useContext(SlidingMenuContext);

    const onToggleSlidingMenu = ():void => {
        return (
            setIsShowSlidingMenu(!isShowSlidingMenu)
        );
    };

    return (
        <div className={isShowSlidingMenu ? "sliding-menu-show" : "sliding-menu-hidden"}>
            <div className="sliding-menu-backdrop" onClick={onToggleSlidingMenu}></div>
            <div className="sliding-menu">
                <div className="sliding-menu-header">
                    <h1>{title}</h1>
                    <Button
                        type="button"
                        modifier="close"
                        clickHeandler={onToggleSlidingMenu}
                    />
                </div>
                <div className="sliding-menu-content">
                    {content}
                </div>
                <div className="sliding-menu-footer">
                    {footer}
                </div>
            </div>
        </div>
    );
};

export default SlidingMenu;
