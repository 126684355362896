import { AgentInfoActions, AgentInfoActionsTypes, IInitialState } from "../../types/agentInfo";

const initialState: IInitialState = {
    agentInfo: {
        next_page_link: null,
        previous_page_link: null,
        total_items: null,
        total_pages: null,
        items_per_page: null,
        page_number: null,
        results: [{
            id: null,
            uid: null,
            user: null,
            token: "",
            version: "",
            os_info: "",
            hostname: "",
            address: "",
            profile_id: null,
            agent_type: "",
            comment: ""
        }]
    },
    loading: false,
    error: null,
};

export const agentInfoReducer = (state = initialState, action: AgentInfoActions): IInitialState =>  {
    switch (action.type) {
        case AgentInfoActionsTypes.GET_AGENT_INFO:
            return {
                ...state,
                loading: true,
                error: null,
                agentInfo: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        uid: null,
                        user: null,
                        token: "",
                        version: "",
                        os_info: "",
                        hostname: "",
                        address: "",
                        profile_id: null,
                        agent_type: "",
                        comment: ""
                    }]
                }
            }
        case AgentInfoActionsTypes.GET_AGENT_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                agentInfo: action.payload
            }
        case AgentInfoActionsTypes.GET_AGENT_INFO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                agentInfo: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        uid: null,
                        user: null,
                        token: "",
                        version: "",
                        os_info: "",
                        hostname: "",
                        address: "",
                        profile_id: null,
                        agent_type: "",
                        comment: ""
                    }]
                }
            }
        default:
            return state
    };
};
