import React from "react";
import { Outlet } from "react-router-dom";

const ProtectedRoutes = () => {
    return (
        <React.Fragment>
            <div className="container">
                <Outlet />
            </div>
        </React.Fragment>
    );
};

export {ProtectedRoutes}
