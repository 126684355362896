import React, { FC } from "react";
import './ProgressBar.scss';

interface ProgressBarProps {
    value: number,
    max: number,
    color?: string
};

const ProgressBar: FC<ProgressBarProps> = ({ value, max, color }) => {
    const percent = Math.round(value * 100 / max);
    const valueWidth = Math.round((percent * 100 / 100) * 1.5 );

    return (
        // <progress max={max} value={value} style={{ backgroundColor: color }}></progress>
        <div className="progress">
            <div className="progress-bar"></div>
            <div className="progress-value" style={{ backgroundColor: color, width: valueWidth }}></div>
        </div>
    );
};

export default ProgressBar;
