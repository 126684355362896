export enum NotificationActionsTypes {
    NOTIFICATION = "NOTIFICATION",
};

export interface INotificationProps {
    isNotification: boolean,
    type: string,
    message: string
};

export interface IInitialState {
    notification: {
        isNotification: boolean,
        type: string,
        message: string
    }
};

interface INotification {
    type: NotificationActionsTypes.NOTIFICATION,
    payload: INotificationProps
};

export type NotificationActions = INotification;
