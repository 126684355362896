import { FC, useContext } from "react";
import { useTypedSelector } from "../../hooks/useTypedSelector";
import { downloadPdf } from "../../downloadPdf";
import PdfReportContext from "../../contexts/pdfReportContext";
import PageOne from "./pages/Page01";
import PageTwo from "./pages/Page02";
import PageThree from "./pages/Page03";
import PageFour from "./pages/Page04";
import PageFive from "./pages/Page05";
import PageSix from "./pages/Page06";
import PageSeven from "./pages/Page07";
import './PdfReport.scss';

const PdfReport: FC = () => {
    const { isShowReportPreview, setIsShowReportPreview } = useContext(PdfReportContext);
    const authData = useTypedSelector(state => state.auth.authData);
    const requestData = useTypedSelector(state => state.statsRequestData.requestData);

    const onToggleReportPreview = (): void => {
        return (
            setIsShowReportPreview(!isShowReportPreview)
        );
    };

    const onToggleReportDownload = (): void => {
        return (
            downloadPdf()
        );
    };

    const renderPeriod = (period: string) => {
        if (period === 'today') {
            return 'Сегодня'
        } else if (period === 'yesterday') {
            return 'Вчера'
        } else if (period === 'week') {
            return 'Неделя'
        } else if (period === 'month') {
            return 'Месяц'
        } else {
            return 'Период'
        }
    };

    return (
        <>
            {isShowReportPreview &&
                <div>
                    <div className="pdf-report-backdrop"></div>
                    <div className="pdf-report-controls">
                        <button
                            type="button"
                            onClick={onToggleReportDownload}
                            className="pdf-report-controls-download"
                        />
                        <button
                            type="button"
                            onClick={onToggleReportPreview}
                            className="pdf-report-controls-close"
                        />
                    </div>
                    <div className="pdf-report">
                        <div className="pdf-report-page">
                            <div className="pdf-report-page-content">
                                <div className="pdf-report-page-head">
                                    <div className="pdf-report-page-logo"></div>
                                    <div className="pdf-report-page-date">
                                        <span>{requestData.period === "range" ? `${requestData.start} - ${requestData.end}` : renderPeriod(requestData.period)}</span>
                                    </div>
                                    <h1 className="pdf-report-page-title">Отчет фильтрации</h1>
                                    <p className="pdf-report-page-account">
                                        {authData && authData.username}
                                    </p>
                                </div>

                                <PageOne />
                                <PageTwo />
                                <PageThree />
                                {/* <PageFour /> */}
                                <PageFive />
                                <PageSix />
                                <PageSeven />

                                <div className="pdf-report-page-footer">
                                    <div className="pdf-report-page-footer-message">
                                        <p>Благодарим вас за использование нашего сервиса, если у вас есть какие-либо вопросы или предложения, мы с радостью ответим на них.</p>
                                    </div>
                                    <div className="pdf-report-page-footer-contacts">
                                        <a
                                            href="https://www.skydns.ru/"
                                            className="pdf-report-page-footer-contacts-website"
                                        >
                                            www.skydns.ru
                                        </a>

                                        <div className="pdf-report-page-footer-contacts-phone-usa">
                                            <p>РФ</p>
                                            <a href="tel:8 (800) 333-33-725">8 (800) 333-33-725</a>
                                        </div>

                                        <a
                                            href="mailto:sales@skydns.ru"
                                            className="pdf-report-page-footer-contacts-mail"
                                        >
                                            sales@skydns.ru
                                        </a>

                                        <div className="pdf-report-page-footer-contacts-phone-all">
                                            <p>Все</p>
                                            <a href="tel:+7 (343) 237-24-30">+7 (343) 237-24-30</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            }
        </>
    )
};

export default PdfReport;
