import { ProfilesActions, ProfilesActionsTypes, IInitialState } from "../../types/profiles";

const initialState: IInitialState = {
    allProfilesIds: [],
    profiles: [],
    loading: false,
    error: null,
};

export const profilesReducer = (state = initialState, action: ProfilesActions): IInitialState => {
    switch (action.type) {
        case ProfilesActionsTypes.ALL_PROFILES_IDS:
            return {
                ...state,
                allProfilesIds: action.payload
            }
        case ProfilesActionsTypes.GET_PROFILES:
            return {
                ...state,
                loading: true,
                error: null,
                profiles: []
            }
        case ProfilesActionsTypes.GET_PROFILES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                profiles: action.payload
            }
        case ProfilesActionsTypes.GET_PROFILES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                profiles: []
            }
        default:
            return state
    };
};
