import React, { FC } from "react";
import Menu from "../menu/Menu";

const Navbar: FC = () => {
    return (
        <nav className="navbar">
            <Menu />
        </nav>
    );
};

export default Navbar;
