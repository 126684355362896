import { StatsDevicesActions, StatsDevicesActionsTypes, IInitialState } from "../../types/statsDevices";

const initialState: IInitialState = {
    statsDevices: "",
    statsDevicesData: [],
    deviceList: [],
    selectedDevice: {
        ident: "",
        data_type: ""
    },
    loading: false,
    error: null,
    getDeviceDomains: [],
    getDevicesLoading: false,
    getDevicesError: null,
    request: null,
    dataResult: null
};

export const statsDevicesReducer = (state = initialState, action: StatsDevicesActions): IInitialState => {
    switch (action.type) {
        case StatsDevicesActionsTypes.GET_STATS_DEVICES:
            return {
                ...state,
                loading: true,
                error: null,
                statsDevices: ""
            }
        case StatsDevicesActionsTypes.GET_STATS_DEVICES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsDevices: action.payload
            }
        case StatsDevicesActionsTypes.GET_STATS_DEVICES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsDevices: ""
            }
        case StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS:
            return {
                ...state,
                getDevicesLoading: true,
                getDevicesError: null,
                getDeviceDomains: []
            }
        case StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_SUCCESS:
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesError: null,
                getDeviceDomains: action.payload
            }
        case StatsDevicesActionsTypes.GET_STATS_DEVICE_DOMAINS_FAILURE:
            return {
                ...state,
                getDevicesLoading: false,
                getDevicesError: action.payload,
                getDeviceDomains: []
            }
        case StatsDevicesActionsTypes.DEVICE_LIST:
            return {
                ...state,
                deviceList: action.payload
            }
        case StatsDevicesActionsTypes.STATS_DEVICES_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsDevicesActionsTypes.STATS_DEVICES_DATA:
            return {
                ...state,
                statsDevicesData: action.payload
            }
        case StatsDevicesActionsTypes.STATS_DEVICES_DATA_RESULT:
            return {
                ...state,
                dataResult: action.payload
            }
        default:
            return state
    };
};
