export enum AdminActionsTypes {
    ADMIN_ID = "ADD_ID",
    GET_ADMINS_LIST = "GET_ADMINS_LIST",
    GET_ADMINS_LIST_SUCCESS = "GET_ADMINS_LIST_SUCCESS",
    GET_ADMINS_LIST_FAILURE = "GET_ADMINS_LIST_FAILURE",
}

export interface IAdminItem {
    id: number | string,
    email: string,
    username?: string,
    role: string,
    handler: any,
    isChecked: boolean
};

export interface IAdminsResponse {
    results: IAdminItem;
    items_per_page: number,
    next_page_link: null | string,
    previous_page_link: null | string
    page_number: number,
    total_items: number,
    total_pages: number,
};

export interface IInitialState {
    adminId: number | string,
    admin: any[],
    adminsList: IAdminsResponse | any,
    update: any[],
    loading: boolean,
    error: null | string,
}

interface IAdminId {
    type: AdminActionsTypes.ADMIN_ID,
    payload: number | string
};

interface IGetAdminList {
    type: AdminActionsTypes.GET_ADMINS_LIST,
};

interface IGetAdminListSuccess {
    type: AdminActionsTypes.GET_ADMINS_LIST_SUCCESS,
    payload: IAdminsResponse | any
};

interface IGetAdminListFailure {
    type: AdminActionsTypes.GET_ADMINS_LIST_FAILURE,
    payload: string
};

export type AdminActions =
    IAdminId |
    IGetAdminList |
    IGetAdminListSuccess |
    IGetAdminListFailure;
