export enum DataExportS3IntegrationActionsTypes {
    S3_INTEGRATION_VERIFY = "S3_INTEGRATION_VERIFY",
    S3_INTEGRATION_VERIFY_SUCCESS = "S3_INTEGRATION_VERIFY_SUCCESS",
    S3_INTEGRATION_VERIFY_FAILURE = "S3_INTEGRATION_VERIFY_FAILURE",

    S3_INTEGRATION_CREATE = "S3_INTEGRATION_CREATE",
    S3_INTEGRATION_CREATE_SUCCESS = "S3_INTEGRATION_CREATE_SUCCESS",
    S3_INTEGRATION_CREATE_FAILURE = "S3_INTEGRATION_CREATE_FAILURE",

    S3_INTEGRATION_UPDATE = "S3_INTEGRATION_UPDATE",
    S3_INTEGRATION_UPDATE_SUCCESS = "S3_INTEGRATION_UPDATE_SUCCESS",
    S3_INTEGRATION_UPDATE_FAILURE = "S3_INTEGRATION_UPDATE_FAILURE",

    S3_INTEGRATION_READ = "S3_INTEGRATION_READ",
    S3_INTEGRATION_READ_SUCCESS = "S3_INTEGRATION_READ_SUCCESS",
    S3_INTEGRATION_READ_FAILURE = "S3_INTEGRATION_READ_FAILURE",

    S3_INTEGRATION_DELETE = "S3_INTEGRATION_DELETE",
    S3_INTEGRATION_DELETE_SUCCESS = "S3_INTEGRATION_DELETE_SUCCESS",
    S3_INTEGRATION_DELETE_FAILURE = "S3_INTEGRATION_DELETE_FAILURE",

    S3_INTEGRATION_VERIFY_REQUEST_DATA = "S3_INTEGRATION_VERIFY_REQUEST_DATA"
};

export interface IS3IntegrationVerifyRequestData {
    endpoint: string,
    access_key: string,
    secret_key: string,
    bucket: string,
    prefix: string,
    include_headers: boolean
};

export interface IS3IntegrationData {
    id: string|number,
    user: string|number,
    endpoint: string,
    access_key: string,
    secret_key: string,
    bucket: string,
    prefix: string|number,
    is_valid: boolean,
    include_headers: boolean,
    last_export: null|string
};

//VERIFY

interface IS3IntegrationVerify {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY,
};

interface IS3IntegrationVerifySuccess {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_SUCCESS,
    payload: any
};

interface IS3IntegrationVerifyFailure {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_FAILURE,
    payload: string
};

interface IS3IntegrationVerifyData {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_VERIFY_REQUEST_DATA,
    payload: IS3IntegrationVerifyRequestData
};

//CREATE

interface IS3IntegrationCreate {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE,
};

interface IS3IntegrationCreateSuccess {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_SUCCESS,
    payload: null|IS3IntegrationData
};

interface IS3IntegrationCreateFailure {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_CREATE_FAILURE,
    payload: string
};

//UPDATE

interface IS3IntegrationUpdate {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE,
};

interface IS3IntegrationUpdateSuccess {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_SUCCESS,
    payload: null|IS3IntegrationData
};

interface IS3IntegrationUpdateFailure {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_UPDATE_FAILURE,
    payload: string
};

//READ

interface IS3IntegrationRead {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ,
};

interface IS3IntegrationReadSuccess {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_SUCCESS,
    payload: null|IS3IntegrationData
};

interface IS3IntegrationReadFailure {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_READ_FAILURE,
    payload: string
};

//DELETE

interface IS3IntegrationDelete {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE,
};

interface IS3IntegrationDeleteSuccess {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_SUCCESS,
    payload: null|string
};

interface IS3IntegrationDeleteFailure {
    type: DataExportS3IntegrationActionsTypes.S3_INTEGRATION_DELETE_FAILURE,
    payload: string
};

export interface IInitialState {
    s3IntegrationIsVerify: null|boolean,
    s3IntegrationVerifyRequestData: IS3IntegrationVerifyRequestData,
    s3IntegrationCreateData: null|IS3IntegrationData,
    s3IntegrationUpdateData: null|IS3IntegrationData,
    s3IntegrationReadData: any,
    s3IntegrationDelete: null|string,
    loading: boolean,
    error: null|string,
};

export type DataExportS3IntegrationActions =
    IS3IntegrationVerify |
    IS3IntegrationVerifySuccess |
    IS3IntegrationVerifyFailure |
    IS3IntegrationCreate |
    IS3IntegrationCreateSuccess |
    IS3IntegrationCreateFailure |
    IS3IntegrationUpdate |
    IS3IntegrationUpdateSuccess |
    IS3IntegrationUpdateFailure |
    IS3IntegrationRead |
    IS3IntegrationReadSuccess |
    IS3IntegrationReadFailure |
    IS3IntegrationDelete |
    IS3IntegrationDeleteSuccess |
    IS3IntegrationDeleteFailure |
    IS3IntegrationVerifyData;
