import ReactDOM from 'react-dom/client';
import App from './components/app/App';
import { BrowserRouter } from 'react-router-dom'
import { Provider } from "react-redux";
import { store } from './store';
import { AuthProvider } from './contexts/authContext';
import { ModalProvider } from "./contexts/modalContext";

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <Provider store={store}>
        <AuthProvider>
            <ModalProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </ModalProvider>
        </AuthProvider>
    </Provider>
);
