import React, { FC, useContext } from "react";
import ModalContext from "../../../contexts/modalContext";
import Button from "../button/Button";
import "./Modal.scss";

interface ModalProps {
    title?: string;
    width?: string;
    content?: React.ReactNode;
};

const Modal: FC<ModalProps> = ({ title, content, width="325" }) => {
    const {
        isShowRecipientsModal,
        setIsShowRecipientsModal,
        isShowS3IntegrationCompleteModal,
        setIsShowS3IntegrationCompleteModal,
        isShowS3IntegrationDeleteModal,
        setIsShowS3IntegrationDeleteModal
    } = useContext(ModalContext);

    const onToggleModal = (): void => {
        if (isShowRecipientsModal) {
            setIsShowRecipientsModal(!isShowRecipientsModal)
        }

        if (isShowS3IntegrationCompleteModal) {
            setIsShowS3IntegrationCompleteModal(!isShowS3IntegrationCompleteModal)
        }

        if (isShowS3IntegrationDeleteModal) {
            setIsShowS3IntegrationDeleteModal(!isShowS3IntegrationDeleteModal)
        }
    };

    return (
        <div className={isShowRecipientsModal || isShowS3IntegrationDeleteModal || isShowS3IntegrationCompleteModal ? "modal-show" : "modal-hidden"}>
            <div className="modal-backdrop" onClick={onToggleModal}></div>
            <div className="modal" style={{maxWidth: width + "px"}}>
                <div className="modal-header">
                    <h1>{title}</h1>
                    <Button
                        type="button"
                        modifier="close"
                        clickHeandler={onToggleModal}
                    />
                </div>
                <div className="modal-content">
                    {content}
                </div>
            </div>
        </div>
    );
};

export default Modal;
