import React, { FC } from "react";
import "./PickedFilter.scss";

interface PickedFilterProps {
    clickHandler: any;
    width?: string;
    margin?: string;
    picked: string;
    title: string
};

const PickedFilter: FC<PickedFilterProps> = ({ width = "264", margin = "0 4px", title, picked, clickHandler}) => {
    return (
        <div className="filter-picked" style={{maxWidth: `${width}px`, margin: margin}}>
            <p className="filter-picked-text">
                {title}: <span>{picked}</span>
            </p>
            <button
                type="button"
                className="filter-picked-reset"
                onClick={clickHandler}
            />
        </div>
    )
};

export default PickedFilter;
