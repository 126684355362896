import React, { FC } from "react";

const HighlightsInfo: FC = () => {
    return (
        <div className="stats-highlights-info">
            <div className="stats-highlights-info-item-filtered">
                <div>
                    <h5 className="stats-highlights-info-item-filtered-title">Разрешенные запросы</h5>
                    <p className="stats-highlights-info-item-filtered-text">Запросы, прошедшие через веб-фильтр, поскольку наши алгоритмы не обнаружили киберугроз или ограничений в категориях вашей учетной записи или настройках домена.</p>
                </div>
            </div>
            <div className="stats-highlights-info-item-blocked">
                <div>
                    <h5 className="stats-highlights-info-item-blocked-title">Заблокированные запросы</h5>
                    <p className="stats-highlights-info-item-blocked-text">Запросы, которые помечены алгоритмами SkyDNS как потенциально опасные или ограничены на основании пользовательских настроек в вашей учетной записи.</p>
                </div>
            </div>
            <div className="stats-highlights-info-item-threats">
                <div>
                    <h5 className="stats-highlights-info-item-threats-title">Предотвращенные угрозы</h5>
                    <p className="stats-highlights-info-item-threats-text">Общее количество предотвращенных угроз включает в себя случаи, идентифицированные нашими алгоритмами как вредоносные или связанные с заблокированными категориями кибербезопасности в настройках вашей учетной записи.</p>
                </div>
            </div>
        </div>
    )
};

export default HighlightsInfo;
