import React, { FC, useState, useEffect } from "react";
import ProgressBar from "../../UI/progress-bar/ProgressBar";
import './Categories.scss';

interface ICategotyItemProps {
    title: string,
    requests: number,
    blocks: number,
    colorIndex?: number,
    colors?: any
};

const CategoryItem: FC<ICategotyItemProps> = ({ title, requests, blocks, colorIndex, colors }) => {
    const [color, setColor] = useState<string>('')

    useEffect(() => {
        setColor(colors.filter((item: any) => item.id === colorIndex)[0].color);
    }, []);

    return (
        <div
            className="stats-categories-cybersecurity-list-cat"
        >
            <p className="stats-categories-cybersecurity-list-cat-title">
                {title}
            </p>
            <div className="stats-categories-cybersecurity-list-cat-requests">
                <div className="stats-categories-cybersecurity-list-cat-requests-counts">
                    <span className="stats-categories-cybersecurity-list-cat-requests-block">
                        {blocks}
                    </span>
                    <span className="stats-categories-cybersecurity-list-cat-requests-all">
                        {requests}
                    </span>
                </div>
                <ProgressBar
                    value={blocks}
                    max={requests}
                    color={color}
                />
                {/* <progress max={requests} value={blocks} style={{ accentColor: color }}></progress> */}
            </div>
        </div>
    );
};

export default CategoryItem;
