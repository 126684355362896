import { AdUsersActions, AdUsersActionsTypes, IInitialState } from "../../types/adUsers";

const initialState: IInitialState = {
    adUsers: {
        next_page_link: null,
        previous_page_link: null,
        total_items: null,
        total_pages: null,
        items_per_page: null,
        page_number: null,
        results: [{
            id: null,
            domain: null,
            hostname: "",
            last_seen: "",
            name: "",
            os_info: "",
            profile_id: null,
            token: null,
            comment:  ""
        }]
    },
    loading: false,
    error: null,
};

export const adUsersReducer = (state = initialState, action: AdUsersActions): IInitialState =>  {
    switch (action.type) {
        case AdUsersActionsTypes.GET_AD_USERS:
            return {
                ...state,
                loading: true,
                error: null,
                adUsers: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        domain: null,
                        hostname: "",
                        last_seen: "",
                        name: "",
                        os_info: "",
                        profile_id: null,
                        token: null,
                        comment:  ""
                    }]
                }
            }
        case AdUsersActionsTypes.GET_AD_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                adUsers: action.payload
            }
        case AdUsersActionsTypes.GET_AD_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                adUsers: {
                    next_page_link: null,
                    previous_page_link: null,
                    total_items: null,
                    total_pages: null,
                    items_per_page: null,
                    page_number: null,
                    results: [{
                        id: null,
                        domain: null,
                        hostname: "",
                        last_seen: "",
                        name: "",
                        os_info: "",
                        profile_id: null,
                        token: null,
                        comment:  ""
                    }]
                }
            }
        default:
            return state
    };
};
