import { StatsCategoriesActions, StatsCategoriesActionsTypes, IInitialState } from "../../types/statsCategories";

const initialState: IInitialState = {
    statsCategories: "",
    statsCategoriesData: [],
    loading: false,
    error: null,
    request: null,
    dataResult: null,
    lastMontnDataResult: null
};

export const statsCategoriesReducer = (state = initialState, action: StatsCategoriesActions): IInitialState => {
    switch (action.type) {
        case StatsCategoriesActionsTypes.GET_STATS_CATEGORIES:
            return {
                ...state,
                loading: true,
                error: null,
                statsCategories: ""
            }
        case StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null,
                statsCategories: action.payload
            }
        case StatsCategoriesActionsTypes.GET_STATS_CATEGORIES_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload,
                statsCategories: ""
            }
        case StatsCategoriesActionsTypes.STATS_CATEGORIES_REQUEST:
            return {
                ...state,
                request: action.payload
            }
        case StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA:
            return {
                ...state,
                statsCategoriesData: action.payload
            }
        case StatsCategoriesActionsTypes.STATS_CATEGORIES_DATA_RESULT:
            return {
                ...state,
                dataResult: action.payload
            }
        case StatsCategoriesActionsTypes.STATS_CATEGORIES_LAST_MONTH_DATA_RESULT:
            return {
                ...state,
                lastMontnDataResult: action.payload
            }
        default:
            return state
    };
};
