import React, { FC } from 'react';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import ButtonLink from '../../UI/button/ButtonLink';
import SlidingMenu from '../../UI/sliding-menu/SlidingMenu';
import '../detailed/Detailed.scss';

const DomainInfo: FC = () => {
    const domainInfo = useTypedSelector(state => state.statsDetailed.getDetailedInfo);
    const { domain, status } = domainInfo;

    const infoItem = (title: string, value: string, valueType: string) => {
        return (
            <div className="stats-detailed-list-item-domain-info-item">
                <p className="stats-detailed-list-item-domain-info-item-title">{title}</p>

                {valueType === "string" &&
                    <span className="stats-detailed-list-item-domain-info-item-value">{value}</span>
                }

                {valueType === "list" &&
                    <ul>
                        <li>clientDeleteProhibited</li>
                        <li>clientTransferProhibited</li>
                        <li>clientUpdateProhibited</li>
                        <li>serverDeleteProhibited</li>
                        <li>serverTransferProhibited</li>
                        <li>serverUpdateProhibited</li>
                    </ul>
                }
            </div>
        )
    };

    return (
        <SlidingMenu
            title="Domain info"
            content={
                <div className="stats-detailed-list-item-domain-info">
                    <div className={`stats-detailed-list-item-${status.toLocaleLowerCase()}`}>{status}</div>
                    {infoItem("DOMAIN NAME:", domain, "string")}
                    <br/>
                    {infoItem("FQDN:", "9052412b-b380-4ba0-99d6-55e10d443222.youtube.com", "string")}
                    {infoItem("REGISTRY DOMAIN ID:", "142504053_DOMAIN_COM-VRSN", "string")}
                    {infoItem("UPDATED DATE:", "2023-01-14T09:25:19Z", "string")}
                    {infoItem("CREATION DATE:", "2005-02-15T05:13:12Z", "string")}
                    {infoItem("CREATION EXPIRY DATE:", "2024-02-15T05:13:12Z", "string")}
                    {infoItem("NAME SERVER:", "ns1.google.com", "list")}
                    {infoItem("NAME SERVER:", "ns1.google.com", "string")}
                </div>
            }
            footer={
                <ButtonLink
                    name="Add to Denylist"
                    link="/"
                    modifier="primary"
                    width="163px"
                />
            }
        />
    )
};

export default DomainInfo;
