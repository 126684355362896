import React, { FC, useState } from "react";
import "./Input.scss";

interface InputProps {
    id?: string;
    type: React.HTMLInputTypeAttribute;
    name?: string;
    value: string;
    width?: string;
    placeholder?: string;
    label?: string;
    isDisabled?: boolean;
    changeHeandler?: React.ChangeEventHandler<HTMLInputElement>;
    blurHandler?: React.FocusEventHandler<HTMLInputElement>;
};

const Input: FC<InputProps> = ({ id, type, name, value, width, placeholder, label, changeHeandler, blurHandler, isDisabled }) => {
    const [isViewPassword, setIsViewPassword] = useState(false);

    const showHidePassword = (): void => {
        setIsViewPassword(!isViewPassword);
    };

    return (
        <div className="input" style={{maxWidth: width}}>
            <label>{label}</label>
            <input
                id={id}
                type={type === "password" ? isViewPassword ? "text" : "password" : type}
                name={name}
                value={value}
                placeholder={placeholder}
                onChange={changeHeandler}
                onBlur={blurHandler}
                disabled={isDisabled}
            />
            {type === "password" &&
            <button
                type="button"
                className={isViewPassword ? "show-password-button" : "hide-password-button"}
                onClick={showHidePassword}
            />}
        </div>
    );
};

export default Input;
