export enum StatsExportActionsTypes {
    STATS_EXPORT = "STATS_EXPORT",
    STATS_EXPORT_SUCCESS = "STATS_EXPORT_SUCCESS",
    STATS_EXPORT_FAILURE = "STATS_EXPORT_FAILURE"
};

export interface IInitialState {
    statsExport:  any,
    loading: boolean,
    error: null | string,
};

interface IStatsExport {
    type: StatsExportActionsTypes.STATS_EXPORT,
};

interface IStatsExportSuccess {
    type: StatsExportActionsTypes.STATS_EXPORT_SUCCESS,
    payload: any
};

interface IStatsExportFailure {
    type: StatsExportActionsTypes.STATS_EXPORT_FAILURE,
    payload: string
};

export type StatsExportActions =
    IStatsExport |
    IStatsExportSuccess |
    IStatsExportFailure;
