import React, { FC } from "react";
import "./Search.scss";

interface SearchProps {
    value: string,
    placeholder?: string;
    width?: string;
    margin?: string;
    padding?: string;
    searchHandler: any
};

const Search: FC<SearchProps> = ({ value, placeholder, width = "320px", margin = "48px 0", padding = "0", searchHandler}) => {
    return (
        <div className="search" style={{margin: margin, padding: padding, width: width}}>
            <input
                type="search"
                value={value}
                placeholder={placeholder}
                className="search-line"
                onChange={searchHandler}
            />
        </div>
    )
};

export default Search;
