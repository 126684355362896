import React, { useState, useRef } from "react";

const PdfReportContext = React.createContext();

export const PdfReportProvider = ({ children }) => {
    const pdfRef = useRef();
    const [isShowReportPreview, setIsShowReportPreview] = useState(false);

    return (
        <PdfReportContext.Provider value={{ pdfRef, isShowReportPreview, setIsShowReportPreview }}>
            {children}
        </PdfReportContext.Provider>
    );
};

export default PdfReportContext;
